import { defineStore } from "pinia";

import { __alert } from "~/stores/alert.store";

import { RoutesLink } from "~/types/routes";
import { Sub } from "~/types/subscriptions";

import type { SubDrive } from "~/classes/subscriptions/SubscriptionDrive";
import type { SubDrivePro } from "~/classes/subscriptions/SubscriptionDrivePro";
import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import type { SubPartner } from "~/classes/subscriptions/SubscriptionPartner";

export type SubClass = SubDrive | SubDrivePro | SubObjectStorage | SubPartner;
type SubTypeMapping = {
	[Sub.Type.Drive]: SubDrive;
	[Sub.Type.DrivePro]: SubDrivePro;
	[Sub.Type.ObjectStorage]: SubObjectStorage;
	[Sub.Type.Partner]: SubPartner;
};
type Subscriptions = {
	[K in Sub.Type]: Record<string, SubTypeMapping[K]>;
};

interface subscriptionInterface {
	subscriptions: Subscriptions;
	isFetched: boolean;
	isFetching: boolean;
	isLoading: Record<Sub.Type, boolean>;
	isDisabled: Record<Sub.Type, boolean>;
	isReadonly: Record<Sub.Type, boolean>;
}

export const __subscription = defineStore("subscriptionStore", {
	/**
	 * Returns an initial state for subscriptions.
	 *
	 * @returns {subscriptionInterface} The initial state for subscriptions.
	 */
	state: (): subscriptionInterface => ({
		subscriptions: {
			[Sub.Type.Drive]: {},
			[Sub.Type.DrivePro]: {},
			[Sub.Type.ObjectStorage]: {},
			[Sub.Type.Partner]: {}
		},
		isFetched: false,
		isFetching: false,
		isLoading: {
			[Sub.Type.Drive]: false,
			[Sub.Type.DrivePro]: false,
			[Sub.Type.ObjectStorage]: false,
			[Sub.Type.Partner]: false
		},
		isDisabled: {
			[Sub.Type.Drive]: false,
			[Sub.Type.DrivePro]: false,
			[Sub.Type.ObjectStorage]: false,
			[Sub.Type.Partner]: false
		},
		isReadonly: {
			[Sub.Type.Drive]: false,
			[Sub.Type.DrivePro]: false,
			[Sub.Type.ObjectStorage]: false,
			[Sub.Type.Partner]: false
		}
	}),
	getters: {
		/**
		 * Retrieves the subscriptions from the given state.
		 *
		 * @param {object} state - The state object.
		 * @returns {Subscription[]} - An array of subscription objects.
		 */
		getSubscriptions: (state: any): SubClass[] => {
			const subscriptions: SubClass[] = [];
			for (const type in state.subscriptions) {
				for (const key in state.subscriptions[type]) {
					subscriptions.push(state.subscriptions[type][key]);
				}
			}
			return subscriptions;
		},

		/**
		 * Returns a function that retrieves the current subscription ID by its type from the application state.
		 *
		 * @param {any} state - The application state containing the current subscriptions.
		 * @returns {Function} - A function that takes a subscription type as input and returns the corresponding subscription ID or null if not found.
		 */
		getCurrentSubscriptionByType: (state: any):
			((type: Sub.Type) => SubTypeMapping[Sub.Type]["id"] | null) => (type: Sub.Type): SubTypeMapping[Sub.Type]["id"] | null => state.currentSubscription[type],

		/**
		 * Returns an array of `SubClass` instances based on the given `state` and `type`.
		 *
		 * @param {any} state - The state object containing the subscription data.
		 * @returns {(type: Sub.Type) => SubClass[]} - A function that takes a `type` parameter and returns an array of `SubClass` instances.
		 */
		getSubscriptionsByType:
			(state: any): ((type: Sub.Type) => SubClass[]) => (type: Sub.Type): SubClass[] => {
				return Object.values(state.subscriptions[type]);
			},

		/**
		 * Retrieves the fetched status from the given state.
		 *
		 * @param {object} state - The state object.
		 * @returns {boolean} - The fetched status.
		 */
		getIsFetched: (state: any): boolean => state.isFetched,

		/**
		 * Retrieves the fetching status from the given state.
		 *
		 * @param {object} state - The state object.
		 * @returns {boolean} - The fetching status.
		 */
		getIsFetching: (state: any): boolean => state.isFetching,

		/**
		 * Retrieves subscription drives from the given state.
		 *
		 * @param state - The state object containing the subscriptions.
		 * @param state.subscriptions - The subscriptions object.
		 * @returns {SubDrive} - The subscription drive.
		 */
		getSubscriptionDrive:
			({ subscriptions }): ((key?: string) => SubDrive | undefined) => (key?: string) => {
				if (!key) {
					return subscriptions[Sub.Type.Drive][
						Object.keys(subscriptions[Sub.Type.Drive])[0]
					] as SubDrive | undefined;
				}

				return subscriptions[Sub.Type.Drive][key] as SubDrive | undefined;
			},
		/**
		 * Retrieves subscription drive pro from the given state.
		 *
		 * @param state - The state object containing the subscriptions.
		 * @param state.subscriptions - The subscriptions object.
		 * @returns {SubDrivePro} - The subscription drive pro.
		 */
		getSubscriptionDrivePro:
			({ subscriptions }): ((key?: string) => SubDrivePro | undefined) => (key?: string) => {
				if (!key) {
					return subscriptions[Sub.Type.DrivePro][
						Object.keys(subscriptions[Sub.Type.DrivePro])[0]
					] as SubDrivePro | undefined;
				}

				return subscriptions[Sub.Type.DrivePro][key] as SubDrivePro | undefined;
			},
		/**
		 * Retrieves subscription object storage from the given state.
		 *
		 * @param state - The state object containing the subscriptions.
		 * @param state.subscriptions - The subscriptions object.
		 * @returns {SubObjectStorage} - The subscription object storage.
		 */
		getSubscriptionObjectStorage:
			({ subscriptions }): ((key?: string) => SubObjectStorage | undefined) => (key?: string) => {
				if (!key) {
					return subscriptions[Sub.Type.ObjectStorage][
						Object.keys(subscriptions[Sub.Type.ObjectStorage])[0]
					] as SubObjectStorage | undefined;
				}

				return subscriptions[Sub.Type.ObjectStorage][key] as SubObjectStorage | undefined;
			},
		/**
		 * Retrieves subscription partner from the given state.
		 *
		 * @param state - The state object containing the subscriptions.
		 * @param state.subscriptions - The subscriptions object.
		 * @returns {SubPartner} - The subscription partner.
		 */
		getSubscriptionPartner:
			({ subscriptions }): ((key?: string) => SubPartner | undefined) => (key?: string) => {
				if (!key) {
					return subscriptions[Sub.Type.Partner][
						Object.keys(subscriptions[Sub.Type.Partner])[0]
					] as SubPartner | undefined;
				}

				return subscriptions[Sub.Type.Partner][key] as SubPartner | undefined;
			},

		/**
		 * Retrieves the loading status of a subscription.
		 *
		 * @param {object} state - The state object containing the loading status.
		 * @returns {(type: SubClass.Type) => boolean} - A function that returns the loading status of a subscription.
		 */
		getIsLoading:
			(state: any): ((type: Sub.Type) => boolean) => (type: Sub.Type): boolean => state.isLoading[type],

		/**
		 * Retrieves the disabled status of a subscription.
		 *
		 * @param {object} state - The state object containing the disabled status.
		 * @returns {(type: SubClass.Type) => boolean} - A function that returns the disabled status of a subscription.
		 */
		getIsDisabled:
			(state: any): ((type: Sub.Type) => boolean) => (type: Sub.Type): boolean => state.isDisabled[type],

		/**
		 * Retrieves the readonly status of a subscription.
		 *
		 * @param {object} state - The state object containing the readonly status.
		 * @returns {(type: SubClass.Type) => boolean} - A function that returns the readonly status of a subscription.
		 */
		getIsReadonly:
			(state: any): ((type: Sub.Type) => boolean) => (type: Sub.Type): boolean => state.isReadonly[type],

		/**
		 * Checks if a subscription is available.
		 *
		 * @param {object} _state - The state object containing the subscriptions.
		 * @returns {(type: SubClass.Type) => boolean} - A function that returns the availability of a subscription.
		 */
		has:
			(_state: any): ((type: Sub.Type) => boolean) => (type: Sub.Type): boolean => __subscription().getSubscriptions.some((sub: SubClass) => sub.getType() === type),

		/**
		 * Check if all subscriptions have a specific status.
		 *
		 * @param _state - The state object containing the subscriptions.
		 * @returns {(status: Sub.Status) => boolean} - A function that returns if all subscriptions have a specific status.
		 */
		hasAll:
			(_state: any): ((status: Sub.Status) => boolean) => (status: Sub.Status): boolean => __subscription().getSubscriptions.every((sub: SubClass) => sub.getStatus() === status),

		/**
		 * Check if one or more subscriptions have a specific status.
		 *
		 * @param _state - The state object containing the subscriptions.
		 * @returns {(status: Sub.Status) => boolean} - A function that returns if one or more subscriptions have a specific status.
		 */
		hasOneOrMore:
			(_state: any): ((status: Sub.Status) => boolean) => (status: Sub.Status): boolean => __subscription().getSubscriptions.some((sub: SubClass) => sub.getStatus() === status),

		/**
		 * Get subscriptions by status.
		 *
		 * @param {object} _state - The state object containing the subscriptions.
		 * @returns {(status: Sub.Status) => SubClass[]} - A function that returns the subscriptions by status.
		 */
		getByStatus:
			(_state: any): ((status: Sub.Status) => SubClass[]) => (status: Sub.Status): SubClass[] => __subscription().getSubscriptions.filter((sub: SubClass) => sub.getStatus() === status)
	},
	actions: {
		/**
		 * Adds a subscription to the list of subscriptions.
		 *
		 * @param {Subscription} subscription - The subscription to be added.
		 */
		addSubscription (subscription: SubClass): void {
			const subscriptionType = subscription.getType();
			this.subscriptions[subscriptionType][subscription.id] = subscription;

			this.defineReadOnly(subscriptionType, subscription);
		},
		/**
		 * Sets the readonly attribute of the given type to true if the subscription's status is Unpaid.
		 *
		 * @param {Sub.Type} type - The type instance whose readonly attribute will be set.
		 * @param {SubClass} subscription - The subscription instance that determines whether the type should be set to readonly.
		 */
		defineReadOnly (type: Sub.Type, subscription: SubClass): void {
			if (subscription.status === Sub.Status.Unpaid) {
				this.setReadonly(type, true);
			}
		},
		// /**
		//  * Removes a subscription from the list of subscriptions.
		//  *
		//  * @param {Subscription} subscription - The subscription to be removed.
		//  * @param key - The key of the subscription.
		//  */
		// removeSubscription<T extends SubClass>(subscription: T, key: string): void {
		// 	const subscriptionType = subscription.getType();
		// 	delete this.subscriptions[subscriptionType][key];
		// },
		/**
		 * Sets the fetched status of subscriptions.
		 *
		 * @param {boolean} newValue - The new fetched status.
		 */
		setFetched (newValue: boolean): void {
			this.isFetched = newValue;
		},

		/**
		 * Sets the fetching status of subscriptions.
		 *
		 * @param {boolean} newValue - The new fetching status.
		 */
		setFetching (newValue: boolean): void {
			this.isFetching = newValue;
		},

		/**
		 * Sets the loading status of a subscription.
		 *
		 * @param type - The type of subscription.
		 * @param newValue - The new loading status.
		 */
		setLoading (type: Sub.Type, newValue: boolean): void {
			if (this.isLoading[type] === undefined) return;
			this.isLoading[type] = newValue;
		},
		// /**
		//  * Sets the disabled status of a subscription.
		//  *
		//  * @param type - The type of subscription.
		//  * @param newValue - The new disabled status.
		//  */
		// setDisabled (type: Sub.Type, newValue: boolean): void {
		// 	if (this.isDisabled[type] === undefined) return;
		// 	this.isDisabled[type] = newValue;
		// },
		/**
		 * Sets the readonly status of a subscription.
		 *
		 * @param type - The type of subscription.
		 * @param newValue - The new readonly status.
		 */
		setReadonly (type: Sub.Type, newValue: boolean): void {
			if (this.isReadonly[type] === undefined) return;
			this.isReadonly[type] = newValue;
		},

		/**
		 * Check PastDue Subscriptions and show an alert
		 *
		 * @param {string} emailSupport - The email of the support
		 */
		checkPastDueSubscriptions (emailSupport: string): void {
			// noinspection TypeScriptValidateTypes
			const pastDueSubscriptionsTypes: string[] = this.getByStatus(Sub.Status.PastDue).map((sub: SubClass) => translate(`label.subscription.${sub.getType()}`));

			if (pastDueSubscriptionsTypes.length <= 0) {
				return;
			}

			const subCount = pastDueSubscriptionsTypes.length;
			const sub = pastDueSubscriptionsTypes.length > 0 ? pastDueSubscriptionsTypes[0] : "";

			const title = translate("notification.past_due.title", { sub }, subCount);
			const message = translate("notification.past_due.message", subCount);

			__alert().add({
				id: "past-due-alert",
				type: "warning",
				title,
				message,
				isDismissible: false,
				actions: [
					{
						label: translate("label.parameters"),
						route: { name: RoutesLink.Params.Subscriptions.Index },
						severity: "secondary",
						icon: "settings"
					},
					{
						label: translate("label.contact"),
						href: `mailto:${emailSupport}`,
						severity: "primary",
						icon: "send-mail"
					}
				]
			});
		},
		/**
		 * Get a subscription by type.
		 *
		 * @param subscriptionType - The type of subscription.
		 * @param key - The key of the subscription.
		 * @returns {SubClass | undefined} - The subscription object.
		 */
		get<ClassType extends SubClass = SubClass>(
			subscriptionType: Sub.Type,
			key?: string
		): ClassType | undefined {
			const subs = this.subscriptions[subscriptionType];
			if (!key) {
				return Object.values(subs)[0] as ClassType | undefined;
			}

			return (subs ? subs[key] : undefined) as ClassType | undefined;
		}
	}
});
