<script lang="ts" setup>
const showModal = ref(false);

onMounted(() => {
	const objectStorage = __subscription().getSubscriptionObjectStorage();
	if (!objectStorage) {
		return;
	}

	const isFirstTrialDay = computed(() => {
		if (!objectStorage.isTrial) {
			return false;
		}

		const trialStart: Date = objectStorage!.cycleStart ?? new Date();
		return new Date().getDate() === trialStart.getDate();
	});

	if (localStorage.getItem("welcome-modal") === "true" || !isFirstTrialDay.value) {
		return;
	}
	showModal.value = true;
});

function startTrial (): void {
	localStorage.setItem("welcome-modal", "true");
	showModal.value = false;
}
</script>

<template>
	<Modal
		:closable="false"
		:is-open="!!showModal"
	>
		<template #title>
			<h3 class="welcome-title">
				<Icon icon="bright-star" />
				{{ translate("modals.welcome.title") }}
			</h3>
		</template>
		<p class="main-text">
			{{ translate("modals.welcome.content") }}
		</p>
		<hr />
		<Row
			col
			gap=".25rem"
			items="center"
			justify="center"
		>
			<Button @click="startTrial">
				{{ translate("modals.welcome.cta.start_trial") }}
			</Button>
		</Row>
	</Modal>
</template>

<style lang="scss" scoped>
.welcome-title {
	@apply text-lg font-bold font-nordique w-full text-center text-secondary
    flex items-center justify-center gap-2;
}

.main-text {
	@apply text-sm font-semibold text-center;
}
</style>
