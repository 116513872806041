import { defineStore } from "pinia";

import { __subscription } from "~/stores/subscription.store";

import { fetchWithCache } from "~/utils/fetch";

import type { PBCollection } from "~/types/pb/collections";
import { type ClientData, ObjectStorageManageMode } from "~/types/pocketbase";
import { Sub } from "~/types/subscriptions";

interface clientsInterface {
	clients: Map<ClientData["id"], ClientData>;
	clientId: ClientData["id"] | null;
}

export const __clients = defineStore("clientsStore", {
	state: (): clientsInterface => ({
		clients: new Map(),
		clientId: null
	}),
	getters: {
		/**
		 * Retrieves all clients as an array filtered by the current subscription.
		 *
		 * @returns {PBClient[]} An array of all clients.
		 */
		clientsArray (): ClientData[] {
			const isPartner = __subscription().has(Sub.Type.Partner);
			return Array.from(this.clients.values())
				.sort((a: ClientData, b: ClientData) => a.ref?.localeCompare(b.ref) ?? 0)
				.filter((client: ClientData) => client.manageMode && client.manageMode !== (
					isPartner
						? "child_client_only"
						: "child_partner_only"
				));
		},
		/**
		 * Retrieves all clients as an array.
		 *
		 * @returns {PBClient[]} An array of all clients.
		 */
		clientsArrayFull (): ClientData[] {
			return Array.from(this.clients.values())
				.sort((a: ClientData, b: ClientData) => a.ref?.localeCompare(b.ref) ?? 0);
		},

		/**
		 * Retrieves the client ID.
		 *
		 * @returns {string | null} The client ID.
		 */
		getClientId (): ClientData["id"] | null {
			// if has only 1 client, return its ID
			if (this.clients.size === 1
				&& this.clientsArray.length === 1
				&& !__subscription().has(Sub.Type.Partner)) {
				// noinspection JSPotentiallyInvalidTargetOfIndexedPropertyAccess
				return this.clientsArray[0].id;
			}

			return this.clientId;
		},
		/**
		 * Retrieves the current team ID of the active client.
		 *
		 * @returns {string | null} The team ID of the current client, or null if not found.
		 */
		getCurrentTeamId (): string | null {
			const client = this.clients.get(this.clientId);
			return client?.teamId || null;
		},
		/**
		 * Retrieves the current client.
		 *
		 * @returns {PBClient | null} The current client.
		 */
		getCurrentClient (): ClientData | null {
			return this.clients.get(this.clientId || "");
		},
		/**
		 * Retrieves the count of clients.
		 *
		 * @returns {number} The count of clients.
		 */
		getClientCount (): number {
			return this.clients.size;
		},

		/**
		 * If has only one client, returns the client.
		 *
		 * @returns {PBClient | null} The client if there is only one, otherwise null.
		 */
		getOnlyClient (): ClientData | null {
			if (this.clients.size === 1 && this.clientsArray.length === 1) {
				return this.clientsArray[0];
			}
		},

		hasAllClientsInPartnerOnlyMode (): boolean {
			return this.clientsArrayFull.every(client => client.manageMode === ObjectStorageManageMode.Partner);
		},

		hasAllClientsInClientOnlyMode (): boolean {
			return this.clientsArrayFull.every(client => client.manageMode === ObjectStorageManageMode.Client);
		}
	},
	actions: {
		/**
		 * Adds a new client to the clients collection.
		 *
		 * @param {PBClient} newClient - The client to be added, identified by its unique ID.
		 * @return {void}
		 */
		addClient (newClient: ClientData): void {
			this.clients.set(newClient.id, newClient);
			void this.fetchAllowedUsage(newClient.id);
			__identifier().createMissingClient(newClient.id);
		},

		/**
		 * Updates an existing client with partial data.
		 * @param clientId - The ID of the client to be updated.
		 * @param updatedData - An object containing the updated data.
		 */
		updateClient (clientId: ClientData["id"], updatedData: Partial<ClientData>) {
			const client: ClientData | undefined = this.clients.get(clientId);
			if (client) {
				const updatedClient = { ...client, ...updatedData };
				this.clients.set(clientId, updatedClient);
			}
		},
		async fetchAllowedUsage (id: ClientData["id"]) {
			const client = this.clients.get(id);
			const ids = await PB.i.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFullList({
					filter: `abo_s3 = "${id}"`,
					fetch: fetchWithCache
				});

			ids.forEach(id => {
				this.updateClient(id.abo_s3, { storage: (convertToToBytes(client.storage || 0)) });
			});
		},

		/**
		 * Sets the client ID.
		 *
		 * @param {string} clientId - The client ID to set.
		 * @return {void}
		 */
		setClientId (clientId: ClientData["id"]): void {
			this.clientId = clientId;
		},
		/**
		 * Retrieves a client by its ID.
		 *
		 * @param clientId - The ID of the client to retrieve.
		 */
		getClient (clientId: ClientData["id"]): ClientData | undefined {
			return this.clients.get(clientId);
		}
	}
});
