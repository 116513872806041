import {
	createRouteCeph2,
	createRouteKC,
	createRouteMongo,
	createRouteNextcloud,
	createRouteSIB,
	createRouteStripe
} from "~/routes/core/routes";

export const routesStripe = {
	getCustomer: createRouteStripe("v2/customer/[customerId]", "GET"),
	updateCustomer: createRouteStripe("v2/customer/update/[customerId]", "POST"),

	getInvoices: createRouteStripe("v2/invoices/[customerId]/[startingAfter]", "GET"),
	getUpcomingInvoices: createRouteStripe("v2/upcominginvoices/[customerId]", "GET"),
	getUpcomingInvoicesBySubscription: createRouteStripe(
		"v2/upcominginvoices/subscription/[subscriptionId]",
		"GET"
	),

	getSubscriptions: createRouteStripe("v2/subscriptions/[customerId]", "GET"),
	deleteS3Subscription: createRouteStripe("v2/s3-delete/[customerId]", "DELETE"),

	getProduct: createRouteStripe("v2/product/[productId]", "GET"),

	createIntent: createRouteStripe("v2/intent/setup/[customerId]", "POST"),

	getPaymentMethods: createRouteStripe("v2/customer/[customerId]/payment_methods", "GET", false),
	deletePaymentMethod: createRouteStripe(
		"v2/customer/[customerId]/payment_methods/[paymentMethodId]",
		"DELETE"
	),

	getDefaultPaymentMethod: createRouteStripe(
		"v2/customer/[customerId]/payment_method/default",
		"GET",
		false
	),
	setAsDefaultPaymentMethod: createRouteStripe(
		"v2/customer/[customerId]/payment_method/[paymentMethodId]",
		"POST"
	),

	transformTrialS3: createRouteStripe("v2/s3-transform/[customerId]", "POST"),

	getPrice: createRouteStripe("price/[priceId]", "GET")
} as const;

export const routesCeph = {
	getIdentifiant: createRouteCeph2("identifiant/[region]/[cluster]/[identifiant]", "GET"),
	createIdentifiant: createRouteCeph2("identifiant/[identifiant]", "POST"),
	deleteIdentifiant: createRouteCeph2(
		"identifiant/[region]/[cluster]/[identifiant]",
		"DELETE"
	),
	updateIdentifiant: createRouteCeph2("identifiant/[region]/[cluster]/[identifiant]", "PUT"),

	setIdentifiantQuota: createRouteCeph2(
		"identifiant/[region]/[cluster]/[identifiant]/quota",
		"PUT"
	),

	getBucketsOfIdentifiant: createRouteCeph2(
		`bucket/[region]/[cluster]/identifiant/[identifiant]`,
		"GET"
	),

	getBucket: createRouteCeph2("bucket/[region]/[cluster]/[bucket]", "GET"),
	createBucket: createRouteCeph2("bucket/[region]/[cluster]/[identifiant]/[bucket]", "POST"),
	updateBucket: createRouteCeph2("bucket/[region]/[cluster]/[bucket]", "PUT"),
	deleteBucket: createRouteCeph2("bucket/[region]/[cluster]/[bucket]", "DELETE"),

	verifyBucketExists: createRouteCeph2("verify/[bucket]", "GET", false)
} as const;

export const routesKC = {
	getUser: createRouteKC("user/[username]", "GET"),
	createUser: createRouteKC("user", "POST"),

	updatePassword: createRouteKC("password/reset", "GET"),

	getCredentials: createRouteKC("get/credentials", "GET"),
	getCredentialsForUsername: createRouteKC("get/credentials/[username]", "GET"),

	enable2FA: createRouteKC("2fa/enable", "GET"),
	disable2FA: createRouteKC("2fa/disable", "GET")
} as const;

export const routesSIB = {
	cancelSubscription: createRouteSIB("send/unsubscription", "POST"),
	sendPBEquipeCreated: createRouteSIB("send/pocketbase/equipe/create", "POST"),
	claimDeleteBucket: createRouteSIB("send/ask-delete-bucket", "POST")
} as const;

export const routesNextcloud = {
	driveInfos: createRouteNextcloud("drive/infos", "GET"),
	driveProInfos: createRouteNextcloud("drive_pro/infos", "GET")
} as const;

export const routesMongo = {
	get: createRouteMongo("data/[db]/[collection]/[aboS3]", "GET")
} as const;
