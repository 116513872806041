import { defineStore } from "pinia";

import { __bucket } from "~/stores/bucket.store";
import { __pbUser } from "~/stores/pb-user.store";
import { __user } from "~/stores/user.store";

import { clearCacheForRoute, fetchWithCache } from "~/utils/fetch";
import { getEnv } from "~/utils/polyfill";
import { getClusterRegionFromPBCluster } from "~/utils/tools";

import { routesCeph } from "~/routes/declarations";

import type { EditableIdentifier, GetIdentifiersOptions, Identifier } from "~/types/identifiants";
import type { PBCollection } from "~/types/pb/collections";
import { Sub } from "~/types/subscriptions";

import { BYTE_MULTIPLIER, BYTE_POW_TO } from "~/composables/config";
import { formatSizeUnits } from "~/composables/units";

const legacyInstances: string[] = [ "fr1" ];

const config = getEnv();

export const __identifier = defineStore("identifiersStore", {
	state: (): {
		identifiers: Map<PBCollection.Clients["id"], Identifier[]>;
		loaded: boolean;
	} => ({
		identifiers: new Map<PBCollection.Clients["id"], Identifier[]>(),
		loaded: false
	}),
	getters: {
		isLoading: state => !state.loaded,
		getIdentifierById:
			state => (id: string): Identifier | undefined => {
				return state.identifiers.get(__clients().getClientId)?.find((identifier: Identifier) => identifier.id === id);
			},
		getBaseId (bucketsName: string[] = []): Identifier {
			return {
				id: "identifiant_leviia_" + getToken(TOKEN_LENGTH),
				description: "ID Random " + getToken(Math.ceil(TOKEN_LENGTH / 4)),
				accessKey: "cléacces_leviia_" + getToken(TOKEN_LENGTH),
				secretKey: "cléacces_leviia_" + getToken(TOKEN_LENGTH),
				// eslint-disable-next-line no-magic-numbers
				active: Math.random() > 0.5,
				buckets: bucketsName,
				objectsAmount: 0,
				spaceUsed: 0,
				hasQuota: false,
				quota: 0,
				isQuotaUnlimited: false,
				size: 0,
				objects: 0,
				isLegacy: false
			} as Identifier;
		},
		getActiveIdentifiers (): Identifier[] {
			return this.identifiers.get(__clients().getClientId)?.filter((identifier: Identifier) => identifier.active);
		},
		getTotalIdentifiersQuota (): number {
			return this.identifiers.get(__clients().getClientId)?.reduce(
				(total: number, identifier: Identifier) => {
					return (
						total
						+ Math.ceil(parseFloat(formatSizeUnits(identifier.quota, "TO", true) + "")
							* 100)
						/ 100
					);
				},
				0
			);
		},
		getTotalIdentifiersObjects (): number {
			return this.identifiers.get(__clients().getClientId)?.reduce(
				(total, identifier) => total + identifier.objects,
				0
			);
		},
		getBucketAmount (): number {
			let total = 0;

			this.identifiers.get(__clients().getClientId)?.forEach((id: Identifier) => {
				total += id.buckets.length ?? 0;
			});

			return total;
		},
		getIdentifiersAmount (): number {
			return this.identifiers.get(__clients().getClientId)?.length ?? 0;
		},
		getTotalSpaceUsed (): number {
			return this.identifiers.get(__clients().getClientId)?.reduce(
				(
					total: any,
					identifier: { spaceUsed: any; }
				) => total + identifier.spaceUsed,
				0
			);
		},
		getTotalQuota (): number {
			return this.identifiers.get(__clients().getClientId)?.reduce(
				(
					total: any,
					identifier: { quota: any; }
				) => total + identifier.quota,
				0
			);
		}
	},
	actions: {
		/**
		 * Retrieves identifiers from PB.
		 *
		 * The method waits for subscription to Abonnements.S3, then fetches the full list of IdentifiantS3 from the 'identifiants_s3' collection in PB. If the list is empty, the 'loaded' property
		 * is set to true and resolves the Promise.
		 * Otherwise, it fetches each identifier by ID and stores the resolved promises in an array. Finally, it filters out any null values and assigns the filtered array to the 'identifiers
		 *' property.
		 *
		 * @returns A Promise that resolves to void.
		 */
		async getIdentifiersFromPB (): Promise<void> {
			try {
				await waitForSubscription(Sub.Type.ObjectStorage);
			} catch (err) {
				console.warn("Error waiting for subscription", err);
				return;
			}
			const ids = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFullList({
					expand: "cluster_v2.cluster_admin"
				});

			const clientIdentifiers = new Map<Identifier["id"], PBCollection.IdentifiantS3["id"]>();
			ids.forEach((id: PBCollection.IdentifiantS3) => {
				clientIdentifiers.set(id.identifiant_s3, id.abo_s3);
			});

			if (!ids || ids.length === 0) {
				this.loaded = true;
				console.warn("No identifiers found");
				return Promise.resolve();
			}

			const promises: Promise<Identifier | undefined>[] = [];
			ids.forEach((id: PBCollection.IdentifiantS3) => {
				if (!id.expand.cluster_v2) return;
				promises.push(this.getIdentifierFromCeph(
					id.identifiant_s3,
					id.expand.cluster_v2.cluster,
					id.abo_s3
				));
			});

			const resolvedPromises = await Promise.all(promises);
			resolvedPromises.forEach((identifier: Identifier) => {
				const clientId = clientIdentifiers.get(identifier.id);
				if (!this.identifiers.has(clientId)) this.identifiers.set(clientId, []);
				// check if not already in the list
				if (!this.identifiers.get(clientId).find((id: Identifier) => id.id === identifier.id)) {
					this.identifiers.get(clientId).push(identifier);
				}
			});

			this.identifiers.get(__clients().getClientId)?.forEach((identifier: Identifier) => {
				const id = ids.find((id: PBCollection.IdentifiantS3) => id.identifiant_s3 === identifier.id);
				if (id) {
					identifier.quota = id.quota * Math.pow(BYTE_MULTIPLIER, 4);
					identifier.hasQuota = id.quota !== -1;
					identifier.isQuotaUnlimited = !identifier.hasQuota;
					identifier.active = !id.suspended;
					identifier.isBlocked = id.leviia_blocked;

					if (id.expand && id.expand.cluster_v2) {
						const { region, cluster } = getClusterRegionFromPBCluster(id.expand.cluster_v2.cluster);
						identifier.region = region;
						identifier.cluster = cluster;
						identifier.endpoint = id.expand.cluster_v2.endpoint;
						identifier.growth
							= id.expand.cluster_v2.expand?.cluster_admin?.growth ?? false;
					}
				}
			});

			this.loaded = true;
		},
		/**
		 * Retrieves an Identifier from a given identifier and baseCluster.
		 *
		 * @param {string} identifier - The identifier to retrieve.
		 * @param {string} baseCluster - The base cluster to use.
		 * @param {string} clientId - The client ID associated with the operation.
		 * @returns {Promise<Identifier | null>} - The retrieved Identifier or null if not found.
		 */
		async getIdentifierFromCeph (
			identifier: string,
			baseCluster: string,
			clientId: string | undefined = undefined
		): Promise<Identifier | undefined> {
			clientId = clientId || __clients().getClientId;

			if (!baseCluster) {
				const id = await PB.i.collection<PBCollection.IdentifiantS3>("identifiants_s3")
					.getFirstListItem(`identifiant_s3 = '${identifier}'`,{
						expand: "cluster_v2.cluster_admin",
						fetch: fetchWithCache
					});

				baseCluster = id?.expand?.cluster_v2?.cluster ?? null;
				if (!baseCluster) {
					console.error("No cluster found for identifier: ", identifier);
					return undefined;
				}
			}

			const { cluster, region } = getClusterRegionFromPBCluster(baseCluster);
			const id = await useFetchRoute<Identifier>(routesCeph.getIdentifiant, {
				identifiant: identifier,
				region,
				cluster
			});

			if (!id || Object.keys(id).length === 0) {
				console.warn("Identifier not found: ", identifier);
				return undefined;
			}

			if (id!.display_name === "" && id!.uid === "") {
				console.error(
					`Error: Identifier ${identifier} cannot be retrieved by server`,
					id
				);
				return undefined;
			}

			const tmpIdentifier = this.cephIDToLocalID(id, cluster, region);
			if (!tmpIdentifier) {
				console.error("Error saving identifier from Ceph");
				return undefined;
			}

			tmpIdentifier.buckets = await this.getBucketsListFromID(
				tmpIdentifier,
				region,
				cluster,
				clientId
			);

			return tmpIdentifier;
		},
		/**
		 * Retrieves a list of buckets from the given identifier.
		 *
		 * @param {Identifier} identifier - The identifier to get the buckets from.
		 * @param {string} region - The region of the identifier.
		 * @param {number} cluster - The cluster of the identifier.
		 * @param {string} clientId - The client ID associated with the operation.
		 * @returns {Promise<string[]>} A promise that resolves to an array of bucket names.
		 */
		async getBucketsListFromID (
			identifier: Identifier,
			region: string,
			cluster: number,
			clientId: string
		): Promise<string[]> {
			if (!identifier) {
				console.error("No identifier provided");
				return [];
			}

			const idBuckets = await useFetchRoute<string[]>(
				routesCeph.getBucketsOfIdentifiant,
				{
					identifiant: identifier.id,
					region,
					cluster
				}
			);

			idBuckets.forEach(bucket => {
				__bucket().addToLocalBucketList(bucket, clientId);
			});

			return idBuckets;
		},
		/**
		 * Converts a CephID to a LocalID.
		 *
		 * @param wrapperId - The CephID object to convert.
		 * @param cluster - The cluster identifier. Defaults to null if not provided.
		 * @param region - The region identifier. Defaults to null if not provided.
		 *
		 * @returns The converted LocalID in the form of an Identifier object.
		 */
		cephIDToLocalID (
			wrapperId: any,
			cluster: string | number | null = null,
			region: string | null = null
		): Identifier | null {
			__user().incrementUserS3Stats(
				{
					size: wrapperId?.stats?.size_actual ?? 0,
					objects: wrapperId?.stats?.num_objects ?? 0
				},
				wrapperId.uid
			);

			const idCluster: string = cluster ?? wrapperId.cluster ?? "1";
			const idRegion: string = region ?? wrapperId.region ?? "fr";

			if (wrapperId.keys.length === 0) {
				console.error("No keys found (secret_key, access_key)");
				return null;
			}

			return {
				id: wrapperId.uid,
				description: wrapperId.display_name,
				accessKey: wrapperId.keys[0]["access_key"],
				secretKey: wrapperId.keys[0]["secret_key"],
				active: wrapperId.suspended === 0,
				spaceUsed: wrapperId.stats?.size_actual ?? 0,
				hasQuota: wrapperId["user_quota"].enabled,
				quota: wrapperId["user_quota"].max_size,
				isQuotaUnlimited: !wrapperId["user_quota"].enabled,
				objectsAmount: 0,
				buckets: [],
				size: wrapperId?.stats?.size_actual ?? 0,
				objects: wrapperId?.stats?.num_objects ?? 0,
				cluster: idCluster,
				region: idRegion,
				isLegacy:
					legacyInstances.includes(idRegion + idCluster)
					&& config.ENVIRONMENT !== "dev"
			} as unknown as Identifier;
		},
		/**
		 * Retrieves the identifiers based on the provided options.
		 * If the identifiers are already loaded, it returns the cached values.
		 * If the identifiers are still loading, it waits for them to finish and then returns an empty array.
		 * If pagination options are provided, it returns a subset of the identifiers based on the page and limit.
		 * If no pagination options are provided, it returns all the identifiers.
		 *
		 * @param {GetIdentifiersOptions} options - The options to customize the retrieval of the identifiers.
		 * @returns {Promise<Identifier[]>} - A promise that resolves to an array of identifiers.
		 */
		async getIdentifiers (options: GetIdentifiersOptions = {}): Promise<Identifier[]> {
			await this.getIdentifiersFromPB();

			let result: Identifier[];

			if (options?.page && options?.limit) {
				const start = (options.page - 1) * options.limit;
				const end = start + options.limit;
				result = this.identifiers.get(__clients().getClientId)
					.toSorted((a: Identifier, b: Identifier) => a?.description?.localeCompare(b?.description))
					.slice(start, end);
			} else {
				result = this.identifiers.get(__clients().getClientId);
			}

			return result;
		},
		/**
		 * Calculates the number of pages needed to display identifiers based on a given limit.
		 *
		 * @param {number} limit - The maximum number of identifiers to display on each page.
		 * @returns {number} - The total number of pages required to display all identifiers.
		 */
		getIdentifiersPages (limit: number): number {
			return Math.ceil(this.identifiers.get(__clients().getClientId)?.length / limit);
		},
		/**
		 * Remove a specified bucket name from all identifiers.
		 *
		 * @param {string} bucketName - The name of the bucket to be removed.
		 *
		 * @return {undefined}
		 */
		removeBucketToAllIdenfifier (bucketName: string): void {
			clearCacheForRoute(routesCeph.getIdentifiant);
			this.identifiers.get(__clients().getClientId)?.forEach((identifiant: Identifier) => {
				const index = identifiant.buckets.findIndex(b => b === bucketName);
				identifiant.buckets.splice(index, 1);
			});
		},
		/**
		 * Returns the identifier associated with a given bucket.
		 *
		 * @param {string} bucket - The bucket to search for.
		 * @returns {Identifier|null} - The identifier associated with the bucket, or null if not found.
		 */
		getIdentifierOfBucket (bucket: string): Identifier | null {
			return (
				this.identifiers.get(__clients().getClientId)?.find((identifier: {
					buckets: string | string[]
				}) => identifier?.buckets?.includes(bucket)) ?? null
			);
		},
		/**
		 * Creates a new identifier.
		 *
		 * @param {Partial<Identifier>} identifier - The partial identifier object.
		 * @return {Promise<void>} - A promise that resolves when the identifier is created.
		 */
		async createIdentifier (identifier: Partial<Identifier>): Promise<void> {
			clearCacheForRoute(routesCeph.getIdentifiant);
			this.loaded = false;
			const aboS3 = __clients().getClientId ?? await __pbUser().getAboS3ID();
			if (!aboS3) {
				console.error("No abo_s3 found");
				return;
			}

			const newId = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.create(
					{
						display_name: identifier.description,
						abo_s3: aboS3,
						quota:
							identifier.hasQuota && identifier.quota
								? identifier.quota / Math.pow(BYTE_MULTIPLIER, 4)
								: -1
					},
					{
						expand: "cluster_v2"
					}
				);

			if (!newId) {
				console.error("Error creating new identifier");
				return;
			}

			const cephId = await this.getIdentifierFromCeph(
				newId.identifiant_s3,
				newId.cluster
			);
			if (!cephId) {
				console.error("Error creating new identifier");
				return;
			}

			cephId.quota = newId.quota * Math.pow(BYTE_MULTIPLIER, BYTE_POW_TO);
			cephId.hasQuota = newId.quota !== -1;
			cephId.isQuotaUnlimited = !cephId.hasQuota;
			cephId.active = !newId.suspended;

			if (newId.expand && newId.expand.cluster_v2) {
				const { region, cluster } = getClusterRegionFromPBCluster(newId.expand.cluster_v2.cluster);
				cephId.region = region;
				cephId.cluster = cluster;
				cephId.endpoint = newId.expand.cluster_v2.endpoint;
			}

			this.identifiers.get(__clients().getClientId)?.push(cephId);

			this.loaded = true;
		},
		async deleteIdentifier (identifierId: string) {
			clearCacheForRoute(routesCeph.getIdentifiant);
			const index = this.identifiers.get(__clients().getClientId)?.findIndex((identifier: {
				id: string
			}) => identifier.id === identifierId);
			const identifier = this.getIdentifierById(identifierId);
			const identifiantS3 = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFirstListItem(`identifiant_s3 = '${identifierId}'`, { fetch: fetchWithCache });

			if (!identifier || identifier.buckets.length > 0 || !identifiantS3) {
				if (identifier && identifier.buckets.length > 0) {
					console.error("Error: Identifier cannot be deleted because it has existing buckets");
				} else {
					console.error("Error: Identifier not found");
				}
				return;
			}

			await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.delete(identifiantS3.id);

			this.identifiers.get(__clients().getClientId)?.splice(index, 1);
		},
		async addBucketToIdenfifier (identifierId: string, bucketName: string) {
			await this.getIdentifiers();
			const identifier = this.getIdentifierById(identifierId);

			if (!identifier) {
				return;
			}

			if (!identifier.buckets) {
				identifier.buckets = [];
			}

			if (identifier.buckets.includes(bucketName)) {
				return;
			}

			identifier.buckets.push(bucketName);
		},
		async editIdentifier (identifierId: string, newValues: EditableIdentifier) {
			clearCacheForRoute(routesCeph.getIdentifiant);
			const identifiantS3 = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFirstListItem(`identifiant_s3 = '${identifierId}'`, { fetch: fetchWithCache });

			if (!identifiantS3) {
				console.error("Identifier not found");
				return;
			}

			PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.update(identifiantS3.id, {
					display_name: newValues.description,
					suspended: !newValues.active,
					quota:
						newValues.hasQuota && newValues.quota
							? newValues.quota / Math.pow(BYTE_MULTIPLIER, 4)
							: -1
				})
				.then(() => {
					const identifier = this.getIdentifierById(identifierId);
					if (!identifier) {
						return;
					}

					identifier.active = newValues.active;
					identifier.description = newValues.description;
					identifier.hasQuota = newValues.hasQuota;
					identifier.quota = identifier.hasQuota ? newValues.quota : 0;
				})
				.catch((err: any) => {
					console.error("Error updating identifier", err);
				});
		},
		async setActive (identifierId: string, active: boolean) {
			clearCacheForRoute(routesCeph.getIdentifiant);
			const identifiantS3 = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFirstListItem(`identifiant_s3='${identifierId}'`, { fetch: fetchWithCache });

			if (!identifiantS3) {
				console.error("Identifier not found");
				return;
			}

			try {
				await PB.i
					.collection<PBCollection.IdentifiantS3>("identifiants_s3")
					.update(identifiantS3.id, {
						suspended: !active
					});

				const identifier = this.getIdentifierById(identifierId);
				if (identifier) {
					identifier.active = active;
				}

				return identifier;
			} catch (err) {
				console.error("Error updating identifier", err);
			}
		},
		async refreshIdentifiers () {
			clearCacheForRoute(routesCeph.getIdentifiant);
			await this.getIdentifiersFromPB();
		},
		hasClusters (clusters: string[]): boolean {
			const clusterSet = new Set(this.identifiers.get(__clients().getClientId)?.map((identifier: Identifier) => identifier.region + identifier.cluster));

			return clusters.some(cluster => clusterSet.has(cluster));
		},
		async fetchIdentifierById (identifierId: string): Promise<Identifier | undefined> {
			await waitForSubscription(Sub.Type.ObjectStorage);
			const id = await PB.i
				.collection<PBCollection.IdentifiantS3>("identifiants_s3")
				.getFirstListItem(`identifiant_s3 = '${identifierId}'`, { fetch: fetchWithCache });

			if (!id) {
				console.warn("Identifier not found: ", identifierId);
				return undefined;
			}

			const cephId = await this.getIdentifierFromCeph(
				id.identifiant_s3,
				id.cluster
			);

			if (!cephId) {
				console.warn("Identifier not found: ", identifierId);
				return undefined;
			}

			cephId.quota = id.quota * Math.pow(BYTE_MULTIPLIER, BYTE_POW_TO);
			cephId.hasQuota = id.quota !== -1;
			cephId.isQuotaUnlimited = !cephId.hasQuota;
			cephId.active = !id.suspended;

			return cephId;
		},
		getEndpoint (identifierId: string): string {
			if (!identifierId) {
				console.error("No identifierId provided");
				return "";
			}

			const identifier = this.getIdentifierById(identifierId);
			if (!identifier) {
				console.error("No identifier found");
				return "";
			}

			return identifier.endpoint ?? "";
		},
		createMissingClient (id: string) {
			if (!this.identifiers.has(id)) {
				this.identifiers.set(id, []);
			}
		}
	}
});
