import { PBEnums } from "~/types/pb/enums";
import type { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

import { TEN_THOUSAND, TIMEOUT_SM } from "~/composables/config";
import { checkNoSubscription } from "~/composables/notifications";
import SubscriptionType = PBEnums.SubscriptionType;

export async function waitForSubscription (subscriptionType: Sub.Type): Promise<void> {
	if (__subscription().getIsFetched) {
		void checkNoSubscription(true);
		return Promise.resolve();
	}

	await waitForPB();
	const subs = __pbUser().getTeam.abonnements;

	if (!subs) {
		console.error("No subscriptions found");
		return Promise.resolve();
	}

	const matches: Record<Sub.Type, SubscriptionType> = {
		[Sub.Type.ObjectStorage]: SubscriptionType.ObjectStorage,
		[Sub.Type.Partner]: SubscriptionType.Partner,
		[Sub.Type.Drive]: SubscriptionType.Drive,
		[Sub.Type.DrivePro]: SubscriptionType.DrivePro
	};

	// check if subs contains the subscriptionType, value is the matching SubscriptionType of the subscriptionType
	const subPb = matches[subscriptionType];
	if (!subPb) {
		console.error(`No matching subscription found for ${subscriptionType}`);
		return Promise.resolve();
	}

	// check if the subscriptionType is in the subscriptions
	if (!subs.includes(subPb)) {
		console.warn("User has not subscription for", subscriptionType, "as", subPb);
		return Promise.resolve();
	}

	if (__subscription().has(subscriptionType)) {
		void checkNoSubscription(true);
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			reject(new Error(`Subscription ${subscriptionType} timed out`));
			try {
				void checkNoSubscription(true);
			} catch (error) {
				console.error(`Error checking subscription: ${error}`);
			}
		}, TEN_THOUSAND);

		const interval = setInterval(() => {
			if (__subscription().has(subscriptionType)) {
				clearTimeout(timeout);
				clearInterval(interval);
				resolve();
			}
		}, TIMEOUT_SM);
	});
}

export function getProductIDFromItem (item: StripeRaw.Subscription | undefined): string | null {
	if (!item) {
		return null;
	}

	const priceProduct = (item as any).price?.product as string;
	const planProduct = item.plan?.product as string;

	return (priceProduct || planProduct) ?? null;
}

export function getProductID (subscription: StripeRaw.Subscription): string | null {
	if ("items" in subscription) {
		const data = subscription.items?.data[0];
		return getProductIDFromItem(data);
	}

	return null;
}
