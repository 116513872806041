import type { Action } from "~/types/layout";
import type { PBCollection } from "~/types/pb/collections";
import type { PBEnums } from "~/types/pb/enums";

export enum ObjectStorageManageMode {
  Client = "child_client_only",
  Partner = "child_partner_only",
  Both = "child_partner_client"
}

export type PBClient = {
  id: string;
  username?: string;
  role: PBEnums.Roles;
  abonnements: PBEnums.SubscriptionType[];
  customer_id?: string;
  parent?: string;
  reference: string;
  created: Date;
  quotaAllowed: number;
  parent_deactivated: boolean;
  leviia_deactivated: boolean;
  s3_mode: ObjectStorageManageMode;

  // Not sure about keeping these fields here
  allowed: number;
  allowed_loading: boolean;
  updated_at?: string;
  usedQuota: number;
};

export type PBCreateUser = {
  email: string;
  password: string;
  passwordConfirm: string;
  nom: string;
  prenom: string;
  abonnements?: ("s3" | "drive_pro" | "drive" | "distributeur")[];
  parent?: string;
  reference: string;
  emailVisibility: boolean;
};

export type PBTeamMember = PBCollection.Clients & {
  role: PBEnums.Roles;
  twoFactorEnabled: boolean;
};

export type PBTeamMembers = Record<PBCollection.Clients["id"], PBTeamMember>;

export type CreateUser = {
  email: string;
  firstname: string;
  lastname: string;
  username: string | null;
  error?: string;
};

export interface TeamMemberData {
  id: string;
  email: string;
  creationDate: string;
  hasTwoFactor: boolean;
  role: PBEnums.Roles;
  canDelete: boolean;
  actions: Action[];
}

export interface ClientData {
  id: string;
  teamId: string;
  ref: string;
  creationDate: Date;
  subscriptionEnd: Date;
  // product: string;
  storage: number;
  quota: number;
  isActive: boolean;
  manageMode: ObjectStorageManageMode | null;
  loading: boolean;
}

export type PBRawAlert = {
  id: string;
  created: string;
  abo_s3: string;
  limit_size_percent: number;
  email_required: string;
  email_optional?: string;
  deactivated?: boolean;
};

export interface ObjectStorageAlertData {
  id: PBRawAlert["id"];
  name: string;
  created: Date;
  limit: number;
  emails: string[];
  isActive: boolean;
}
