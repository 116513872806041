import { defineStore } from "pinia";

import { __alert } from "~/stores/alert.store";
import { __pbUser } from "~/stores/pb-user.store";
import { __stripe } from "~/stores/stripe.store";
import { __subscription } from "~/stores/subscription.store";

import { Sub } from "~/types/subscriptions";
import type { BillingUser, KCUser, User } from "~/types/user";

import { PHONE_LEN } from "~/composables/config";
import { arraysEqual } from "~/composables/tools";

export const __user = defineStore("userStore", {
	state: () => <
			{
				user: User;
				oldKCUser: KCUser | null;
				incrementedIds: Set<string>;
			}
		>{
			user: {
				// ========= Détails =========
				firstName: "", // Prénom
				familyName: "", // Nom
				enterprise: "", // Entreprise
				enterpriseRole: "", // Rôle dans l'entreprise

				// ========= Contact =========
				email: "", // Email
				phone: "", // Téléphone

				// TODO: Move this to stripe
				// ========= Adresse de facturation =========
				billing: {
					phone: "", // Téléphone de facturation
					email: "", // Email de facturation
					enterprise: "", // Entreprise de facturation
					address: "", // Adresse de facturation
					address2: "", // Complément d'adresse de facturation
					zipCode: "", // Code postal de facturation
					city: "" // Ville de facturation
				},
				// TODO: Check if this is still used
				// ========= Autres =========
				scopes: [], // Scopes
				emailVerified: false, // Email vérifié
				quota: "", // Quota
				username: "", // Nom d'utilisateur
				instance: "", // Instance
				locale: "", // Langue
				adminGroups: [], // Groupes d'admin
				hasPaid: false, // A payé
				enabled: false, // Activé
				groups: [], // Groupes
				hasOTP: false
			},
			oldKCUser: null,
			incrementedIds: new Set<string>(),
			pbUser: null,
			clients: {},
			parent: null,
			parentHasPaid: false
		},
	getters: {
		getUser: state => {
			return state.user;
		},
		hasProblem: (state: any) => {
			const hasAbo = __subscription().getSubscriptions.length > 0;

			if (!hasAbo && !__pbUser().hasParent) {
				const groups = state.user?.groups || [];
				const adminGroups = state.user?.adminGroups || [];

				const nonAdminGroups = groups.filter((group: string) => group !== "admin" && group !== "leviia");

				if (nonAdminGroups.length > 0 && adminGroups.length === 0) {
					return false;
				}
			}

			return !hasAbo && !__stripe().getCustomerId && !__stripe().getParentCustomerId;
		},
		getChangedValueKeycloak: state => {
			const newKCUser: Partial<KCUser> = {
				email: state.user.email,
				email_verified: state.user.emailVerified,
				family_name: (state.user.familyName ?? "").toLowerCase(),
				given_name: (state.user.firstName ?? "").toLowerCase(),
				"leviia-enabled": state.user.enabled,
				"leviia-groups": state.user.groups,
				"leviia-groups-admin": state.user.adminGroups.join(","),
				"leviia-has-paid": state.user.hasPaid,
				"leviia-instance": state.user.instance,
				"leviia-quota": state.user.quota,
				locale: state.user.locale,
				preferred_username: state.user.username,
				scope: state.user.scopes.join(" ")
			};

			// get the list of keys that have changed
			const changedKeys = Object.keys(newKCUser).filter(key => {
				const newVal = newKCUser[key as keyof KCUser];
				const oldVal = state.oldKCUser?.[key as keyof KCUser];

				if (Array.isArray(newVal) && Array.isArray(oldVal)) {
					return !arraysEqual(newVal, oldVal);
				} else {
					return newVal !== oldVal;
				}
			});

			const changedUser: Partial<KCUser> = {};

			changedKeys.forEach(key => {
				const currentKey: keyof KCUser = key as keyof KCUser;
				// @ts-ignore
				changedUser[currentKey] = newKCUser[currentKey] as any;
			});

			return changedUser;
		},
		isClientsLoading: (state: any) => {
			for (const key in state.clients) {
				if (state.clients[key].allowed_loading) {
					return true;
				}
			}
			return false;
		},
		getUrlPro: (state: any) => state.user.urlPro.replace(/(^\w+:|^)\/\//, ""),

		getUserName: (state: any) => state.user.username,
		getFirstName: (state: any) => state.user.firstName,
		getFamilyName: (state: any) => state.user.familyName,
		getEmail: (state: any) => state.user.email,
		getPhone: (state: any) => state.user.phone,
		hasTeamFullAccess: () => {
			// if is partner (metered or fixed)
			if (__subscription().has(Sub.Type.Partner)) {
				return true;
			}

			// if is s3 and has parent (metered or fixed)
			if (__subscription().has(Sub.Type.ObjectStorage) && __pbUser().hasParent) {
				return true;
			}

			const objectStorage = __subscription().getSubscriptionObjectStorage();

			// if is s3 (fixed)
			return (
				__subscription().has(Sub.Type.ObjectStorage)
				&& !__pbUser().hasParent
				&& !unref(objectStorage!.isUnlimited)
				&& !objectStorage!.isTrial
			);
		},
		hasTeamReadOnly: () => {
			const objectStorage = __subscription().getSubscriptionObjectStorage();

			// if is s3 trial
			if (objectStorage && objectStorage.isTrial) {
				return true;
			}

			// if is s3 (metered)
			return !!(
				__subscription().has(Sub.Type.ObjectStorage)
				&& !__pbUser().hasParent
				&& unref(objectStorage)
				&& unref(objectStorage?.isUnlimited)
			);
		}
	},
	actions: {
		/**
		 * Set the given user.
		 *
		 * @param {KCUser} newUser - The new user object to be set.
		 *
		 * @return {Promise<void>} - A promise that resolves when the user is set.
		 */
		setUser (newUser: KCUser): void {
			this.oldKCUser = newUser;
			if ("customer_id" in newUser) {
				__stripe().setCustomerId(newUser.customer_id);
			}
			void __pbUser().init(newUser.preferred_username);
			void __stripe().getInfos();

			this.setFirstName(newUser.given_name ?? "");
			this.setFamilyName(newUser.family_name ?? "");
			this.setEmail(newUser.email);

			this.mapUserProperties(newUser, this.user);
			void __pbUser().init(this.user.username);

			__alert()
				.fetchNotifications()
				.then(() => {
					__alert().watchSubscription();
				});
		},

		/**
		 * Maps user properties from one user object to another.
		 *
		 * @param {KCUser} fromUser - The source user object.
		 * @param {User} toUser - The destination user object.
		 * @return {void} - This method does not return anything.
		 */
		mapUserProperties (fromUser: KCUser, toUser: User): void {
			const properties = {
				scopes: fromUser?.scope?.split(" ") ?? [],
				emailVerified: fromUser.email_verified,
				quota: fromUser["leviia-quota"] || "1 To",
				username: fromUser.preferred_username,
				instance: fromUser["leviia-instance"] || "fr1",
				locale: fromUser.locale,
				adminGroups: fromUser["leviia-groups-admin"]?.split(",") ?? [],
				hasPaid: fromUser["leviia-has-paid"] ?? false,
				customerId: fromUser.customer_id ?? "",
				enabled: fromUser["leviia-enabled"] ?? false,
				groups: fromUser["leviia-groups"] ?? [],
				familyName: capitalize(fromUser.family_name ?? ""),
				email: fromUser.email,
				urlPro: fromUser.url_pro ?? ""
			};

			for (const key in properties) {
				// @ts-ignore
				toUser[key] = properties[key];
			}
		},
		/**
		 * Sets the billing details for the user.
		 *
		 * @param {BillingUser} newBillingUser - The new billing user object containing updated billing details
		 */
		setBillingUser (newBillingUser: BillingUser) {
			this.user.billing = { ...this.user.billing, ...newBillingUser };
		},
		/**
		 * Update the user's information based on the provided newUser object.
		 *
		 * @param {Partial<User>} newUser - A partial object containing the updated information for the user.
		 * @return {void} - This method does not return a value.
		 */
		updateUser (newUser: Partial<User>): void {
			// Update user
			Object.assign(this.user, newUser);

			const changedUser = this.getChangedValueKeycloak;
			console.info(changedUser);

			// const res = await useFetch(`${config.WRAPPER_KEYCLOACK}/update_user_oidc/`,
			//     'POST',
			//     {},
			//     changedUser
			// )
		},
		/**
		 * Increments the user's S3 statistics by the given values.
		 *
		 * @param {object} stats - An object containing the size and objects values to be incremented.
		 * @param {number} stats.size - The size to be added to the user's S3 size.
		 * @param {number} stats.objects - The number of objects to be added to the user's S3 objects count.
		 * @param {string} id - The user's ID.
		 *
		 * @returns {void}
		 */
		incrementUserS3Stats (
			stats: {
				size: number;
				objects: number;
			},
			id: string
		): void {
			if (this.incrementedIds.has(id) || stats.size <= 0 || stats.objects <= 0) {
				return;
			}

			this.incrementedIds.add(id);

			const objectStorage = __subscription().getSubscriptionObjectStorage();
			if (objectStorage) {
				objectStorage.incrementSizeDisposed(stats.size);
				objectStorage.incrementObjectsAmount(stats.objects);
			}
		},
		/**
		 * Resets the S3 statistics for the user.
		 *
		 * @return {void}
		 */
		resetUserS3Stats (): void {
			const objectStorage = __subscription().getSubscriptionObjectStorage();
			if (!objectStorage) {
				return;
			}

			objectStorage.reset();
			this.incrementedIds.clear();
		},
		/**
		 * Sets the first name of the user.
		 *
		 * @param {string} firstName - The new first name to be set.
		 *
		 * @return {void}
		 */
		setFirstName (firstName: string): void {
			if (this.getFirstName.length === 0) {
				this.user.firstName = firstName;
			}
		},
		/**
		 * Set the family name of the user.
		 *
		 * @param {string} familyName - The family name to set.
		 *
		 * @return {void}
		 */
		setFamilyName (familyName: string): void {
			if (this.getFamilyName.length === 0) {
				this.user.familyName = familyName;
			}
		},
		/**
		 * Sets the email of the user.
		 *
		 * @param {string} email - The email to set.
		 *
		 * @return {void}
		 */
		setEmail (email: string): void {
			if (this.getEmail.length === 0) {
				this.user.email = email;
			}
		},
		/**
		 * Sets the phone number for the user if the existing phone number is empty.
		 *
		 * @param {string} phone - The phone number to be set.
		 *
		 * @return {void}
		 */
		setPhone (phone: string): void {
			if (this.getPhone.length === 0) {
				if (phone.startsWith("+33")) {
					phone = phone.replace("+33", "0");
				} else if (phone.length === PHONE_LEN) {
					phone = `0${phone}`;
				}

				this.user.phone = phone;
			}
		},
		getUserByUsername (username: string): Promise<void> {
			return __pbUser().init(username);
		}
	}
});
