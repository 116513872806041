import { slugify } from "~/utils/string";

import type { AlertType } from "~/types/alert";
import type { StripeRaw } from "~/types/stripe/raw";
import type { Sub } from "~/types/subscriptions";

import { SubscriptionConfig } from "./SubscriptionConfig";

/**
 * SubscriptionValidator is a utility class for validating subscription data.
 */
export class SubscriptionValidator {
	/**
	 * Checks if the given subscription status is valid.
	 *
	 * @param {(Sub.Status | string)} status - The subscription status to be checked.
	 * @returns {boolean} - Returns true if the subscription status is valid, otherwise returns false.
	 */
	public static isValidSubscriptionStatus (status: Sub.Status | string): boolean {
		const validStatus = [ ...SubscriptionConfig.subscriptionsConsideredAsValid, ...SubscriptionConfig.subscriptionsConsideredAsReadonly ];

		return validStatus.includes(status as Sub.Status);
	}

	/**
	 * Checks if a subscription type is valid.
	 *
	 * @param {string} type - The subscription type to check.
	 * @returns {boolean} - True if the subscription type is valid, false otherwise.
	 */
	public static isValidSubscriptionType (type: string): boolean {
		const validTypes = Object.values(SubscriptionConfig.possiblesSubscriptionTypes)
			.flat()
			.map(value => slugify(value));

		return validTypes.includes(slugify(type));
	}

	/**
	 * Retrieves the subscription type based on the provided type.
	 *
	 * @param {string} type - The type of the subscription.
	 * @returns {Sub.Type|null} - The subscription type matching the provided type, or null if no match is found.
	 */
	public static getSubscriptionType (type: string): Sub.Type | null {
		for (const [ key, values ] of Object.entries(SubscriptionConfig.possiblesSubscriptionTypes)) {
			const slugifiedValues = values.map(value => slugify(value));
			if (slugifiedValues.includes(slugify(type))) {
				return key as Sub.Type;
			}
		}

		return null;
	}

	/**
	 * Checks if the given item is of the expected type.
	 *
	 * @param {StripeRaw.Item} item - The item to be checked.
	 * @param {StripeRaw.ItemType} expectedType - The expected type of the item.
	 * @returns {boolean} Returns true if the item is of the expected type, false otherwise.
	 */
	public static isValidItemType (item: StripeRaw.Item, expectedType: StripeRaw.ItemType): boolean {
		if (!item) {
			console.error(`❌ ${expectedType} item not found.`);
			return false;
		}

		if (item.object !== expectedType) {
			console.error(`❌ Invalid object type for ${expectedType} ${item.id}.`);
			return false;
		}

		return true;
	}

	/**
	 * Determines if a given subscription type can have multiple subscriptions.
	 *
	 * @param {Sub.Type} type - The subscription type.
	 * @returns {boolean} - True if the subscription type can have multiple subscriptions; otherwise, false.
	 */
	public static canHaveMultipleSubscriptions (type: Sub.Type): boolean {
		return SubscriptionConfig.subscriptionsAllowedToMultiple.includes(type);
	}

	/**
	 * Determines if statusA is higher priority than statusB based on predefined subscription priorities.
	 *
	 * @param {Sub.Status} statusA - The first subscription status to compare.
	 * @param {Sub.Status} statusB - The second subscription status to compare.
	 * @returns {boolean} - True if statusA is higher priority, false otherwise.
	 */
	public static isMostHighPriority (statusA: Sub.Status, statusB: Sub.Status): boolean {
		return (
			SubscriptionConfig.subscriptionsPriority.indexOf(statusA)
			< SubscriptionConfig.subscriptionsPriority.indexOf(statusB)
		);
	}

	/**
	 * Returns the color level of a given status.
	 *
	 * @param {Sub.Status} status - The status for which to get the color level.
	 * @returns {AlertType | undefined} The color level of the given status, or undefined if not found.
	 */
	public static getStatusColorLevel (status: Sub.Status): AlertType | undefined {
		return SubscriptionConfig.statusLevels[status] ?? undefined;
	}
}
