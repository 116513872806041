import { PBEnums } from "~/types/pb/enums";
import type { PBTeamMember } from "~/types/pocketbase";

import { isDebugMode } from "~/composables/log";

export enum RoleRules {
	Params_User,
	Params_Security,
	Params_Subscriptions,
	Params_Teams,

	Params_Stripe_Coord,
	Params_Stripe_PaymentMethod,
	Params_Stripe_Invoice,

	Partner,

	ObjectStorage,
	ObjectStorage_Identifier,
	ObjectStorage_Bucket,
	ObjectStorage_Alerts,
	ObjectStorage_Usage,

	Drive,

	DrivePro,

	Discover
}

export enum Rules {
	All,
	ReadOnly,
	None
}

const roleAuthorizations: Omit<
	Omit<
		Record<PBEnums.Roles, Record<RoleRules, Rules>>,
		PBEnums.Roles.Owner
	>,
	PBEnums.Roles.None
> = {
	[PBEnums.Roles.Admin]: {
		[RoleRules.Params_User]: Rules.All,
		[RoleRules.Params_Security]: Rules.All,
		[RoleRules.Params_Subscriptions]: Rules.All,
		[RoleRules.Params_Teams]: Rules.All,

		[RoleRules.Params_Stripe_Coord]: Rules.All,
		[RoleRules.Params_Stripe_PaymentMethod]: Rules.All,
		[RoleRules.Params_Stripe_Invoice]: Rules.All,

		[RoleRules.Partner]: Rules.All,

		[RoleRules.ObjectStorage]: Rules.All,
		[RoleRules.ObjectStorage_Identifier]: Rules.All,
		[RoleRules.ObjectStorage_Bucket]: Rules.All,
		[RoleRules.ObjectStorage_Alerts]: Rules.All,
		[RoleRules.ObjectStorage_Usage]: Rules.All,

		[RoleRules.Drive]: Rules.All,

		[RoleRules.DrivePro]: Rules.All,

		[RoleRules.Discover]: Rules.All
	},
	[PBEnums.Roles.Invoice]: {
		[RoleRules.Params_User]: Rules.All,
		[RoleRules.Params_Security]: Rules.All,
		[RoleRules.Params_Subscriptions]: Rules.ReadOnly,
		[RoleRules.Params_Teams]: Rules.None,

		[RoleRules.Params_Stripe_Coord]: Rules.All,
		[RoleRules.Params_Stripe_PaymentMethod]: Rules.All,
		[RoleRules.Params_Stripe_Invoice]: Rules.All,

		[RoleRules.Partner]: Rules.None,

		[RoleRules.ObjectStorage]: Rules.None,
		[RoleRules.ObjectStorage_Identifier]: Rules.None,
		[RoleRules.ObjectStorage_Bucket]: Rules.None,
		[RoleRules.ObjectStorage_Alerts]: Rules.All,
		[RoleRules.ObjectStorage_Usage]: Rules.None,

		[RoleRules.Drive]: Rules.None,

		[RoleRules.DrivePro]: Rules.None,

		[RoleRules.Discover]: Rules.None
	}
};

export function getRoleAuthorization (role: PBEnums.Roles, rule: RoleRules): Rules {
	if (role === PBEnums.Roles.Owner) {
		return Rules.All;
	}

	if (role === PBEnums.Roles.None) {
		return Rules.None;
	}

	if (role in roleAuthorizations && rule in roleAuthorizations[role]) {
		return roleAuthorizations[role][rule];
	}

	return Rules.None;
}

export function getAuthorization (rule: RoleRules): Rules {
	const role = __pbUser().getRole;
	return getRoleAuthorization(role, rule);
}

export function hasAuthorization (rule: RoleRules): boolean {
	if (isDebugMode()) return true;

	const authorization = getAuthorization(rule);

	return authorization !== Rules.None;
}

export function isReadOnlyAuthorization (rule: RoleRules): boolean {
	const authorization = getAuthorization(rule);

	return authorization === Rules.ReadOnly;
}

export function sortMembers (memberA: PBTeamMember, memberB: PBTeamMember): number {
	const rolePriority: Record<PBEnums.Roles, number> = {
		[PBEnums.Roles.Owner]: 0,
		[PBEnums.Roles.Admin]: 1,
		[PBEnums.Roles.Invoice]: 2,
		[PBEnums.Roles.None]: 999
	};

	const roleComparison = rolePriority[memberA.role] - rolePriority[memberB.role];
	if (roleComparison !== 0) {
		return roleComparison;
	}

	// Si les rôles sont identiques, trier par date de création
	const dateA = new Date(memberA.created);
	const dateB = new Date(memberB.created);
	return dateA.getTime() - dateB.getTime();
}
