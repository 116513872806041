<script lang="ts" setup>
import { __user } from "~/stores/user.store";

import type { MenuItemProps, MenuSelector } from "~/types/menus";
import { RoutesLink } from "~/types/routes";

const isOpen = ref(false);

const { locale } = useI18n();

const currentLanguage = ref(locale.value);

const isLocal = computed<boolean>(() => document?.location?.hostname === "localhost");
const locales = computed(() => {
	const l = [
		{
			name: "Français",
			value: "fr"
		},
		{
			name: "English",
			value: "en"
		}
	];

	if (isLocal.value) {
		l.push({
			name: "Jap",
			value: "jp"
		});
	}

	return l;
});

function getLanguageSelections (): MenuSelector[] {
	return locales.value.map(currentLocale => ({
		name: currentLocale.name,
		value: currentLocale.value,
		action: (): void => {
			currentLanguage.value = locale.value;
			locale.value = currentLocale.value;
			localStorage.setItem("locale", currentLocale.value);
		}
	}));
}

const dropdownElements: MenuItemProps[] = [];
dropdownElements.push({
	name: "menu_user.parameters",
	icon: "settings",
	link: { name: RoutesLink.Params.Infos }
});

// if (useRuntimeConfig().public.ENVIRONMENT === "dev") {
dropdownElements.push({
	name: "menu_user.language",
	icon: "globe",
	selectionsValue: currentLanguage.value,
	selections: getLanguageSelections()
});
// }

dropdownElements.push({
	name: "menu_user.logout",
	icon: "log-out",
	link: { name: RoutesLink.Logout }
});

dropdownElements.forEach((element: MenuItemProps) => {
	element.theme = "light";
});

function toggleDropdown (): void {
	isOpen.value = !isOpen.value;
}
</script>

<template>
	<div class="header-dropdown">
		<div
			v-if="isOpen"
			class="header-dropdown__fader"
			@click="toggleDropdown"
		/>
		<PButton
			:active="Boolean(isOpen)"
			outlined
			size="small"
			@click="toggleDropdown"
		>
			<Icon
				icon="profile-circle"
				width="1.5em"
			/>
			<span>{{ translate("menu_user.welcome") }} <strong>{{ __user().user.firstName }}</strong></span>
			<Icon
				v-show="!isOpen"
				icon="nav-arrow-down"
				width="1.5em"
			/>
			<Icon
				v-show="isOpen"
				icon="nav-arrow-up"
				width="1.5em"
			/>
		</PButton>
		<div
			v-show="isOpen"
			class="header-dropdown__content"
		>
			<nav>
				<MenuItem
					v-for="element in dropdownElements"
					:key="element.name"
					:item="element"
					is-dropdown
					is-hoverable
					is-light
					@click="isOpen = false"
				/>
			</nav>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@use "@/assets/css/elements.scss";

.header-dropdown {
	@apply relative mt-1 z-[999];

	&__fader {
		@apply fixed top-0 left-0 w-full h-full z-10;
	}

	&__btn {
		@apply text-sm;
		strong {
			@apply font-bold;
		}
	}

	&__content {
		@apply absolute top-full right-0 bg-light border border-cream rounded-2xl p-4
        mt-2 z-10 min-w-[240px] font-bold inline-flex flex-col;
		@extend %primary-shadow;

		nav {
			@apply grid w-full gap-2;
			a:last-child {
				@apply relative;
				&::after {
					@apply absolute bottom-full left-0 w-full h-[1px] bg-cream mb-1;
					content: "";
				}
			}
		}
	}
}
</style>
