import { Sub } from "~/types/subscriptions";

import { __clients } from "#imports";
import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async to => {
	let clientID = to.params?.clientId as string | undefined;

	await waitForSubscription(Sub.Type.Partner);
	await waitForSubscription(Sub.Type.ObjectStorage);

	const os = __subscription().getSubscriptionsByType(Sub.Type.ObjectStorage);
	const clientIds = os.map(sub => sub.id);

	if (!__subscription().has(Sub.Type.Partner)) {
		clientID = clientIds[0];
		if (clientIds.length > 1) {
			log("🔍 More than one object storage subscription found. Defaulting to first one.\n\n"
				+ ""
				+ "Your current subscription is not a partner subscription, and only partners can access multiple object storage subscriptions.");
		}
	}

	log(`🔑 Client ID: %c${clientID}`, "font-weight: bold");
	__clients().setClientId(clientID);
});
