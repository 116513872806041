<script lang="ts" setup>
import { __subscription } from "~/stores/subscription.store";

import type { MenuCategoryProps, MenuItemProps } from "~/types/menus";
import { RoutesLink } from "~/types/routes";
import { Sub } from "~/types/subscriptions";

import { __clients, hasAuthorization, RoleRules, toValue } from "#imports";
import type { SubDrive } from "~/classes/subscriptions/SubscriptionDrive";
import type { SubDrivePro } from "~/classes/subscriptions/SubscriptionDrivePro";
import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import { TRIAL_DAY_LEFT_ALERT } from "~/composables/config";
import { daysBetweenNow } from "~/composables/date";
import { useObjectStorageStats } from "~/composables/subscriptions/useObjectStorageStats";
import { toBytes } from "~/composables/units";

const config = useRuntimeConfig();

const objectStorage = computed<SubObjectStorage | undefined>(() => __subscription().getSubscriptionObjectStorage());
const drive = computed<SubDrive | undefined>(() => __subscription().getSubscriptionDrive());
const drivePro = computed<SubDrivePro | undefined>(() => __subscription().getSubscriptionDrivePro());

const showClientObjectStorage = computed<boolean>(() => __subscription().has(Sub.Type.ObjectStorage) && !__clients().hasAllClientsInPartnerOnlyMode);
const showPartnerObjectStorage = computed<boolean>(() => __subscription().has(Sub.Type.Partner) && !__clients().hasAllClientsInClientOnlyMode);

const categoryGeneral = computed<MenuCategoryProps>((): MenuCategoryProps => {
	return {
		name: "menu.general",
		icon: "cloud",
		items: [
			{
				name: "menu.general_dashboard",
				icon: "home",
				link: { name: RoutesLink.Home },
				visible: true
			}
		]
	};
});

const categoryPartners = computed<MenuCategoryProps>((): MenuCategoryProps => {
	const cat: MenuCategoryProps = {
		name: "menu.partners",
		icon: "rocket",
		items: []
	};

	if (__subscription().has(Sub.Type.Partner)) {
		cat.items.push({
			name: "menu.partners_clients",
			icon: "community",
			link: { name: RoutesLink.Partner.Index },
			visible: true
		});

		if (!showPartnerObjectStorage.value) {
			return cat;
		}

		useObjectStorageStats(objectStorage.value!);

		addObjectStorageIdentifier(cat, __clients().getClientId);

		addObjectStorageBucket(cat, __clients().getClientId);

		addObjectStorageAlerts(cat,__clients().getClientId);

		addSuperset(cat, __clients().getClientId);
	}

	return cat;
});

function addObjectStorageTrial (cat: MenuCategoryProps): void {
	const s3Trial = objectStorage.value
		? objectStorage.value.getTrialInformations()
		: null;
	if (objectStorage.value.isTrial && s3Trial) {
		const daysLeft = daysBetweenNow(s3Trial.trialEnd);

		const used = computed<number>(() => unref(objectStorage.value!.spaceDisposed) ?? 0);
		const total = computed<number>(() => toBytes(unref(objectStorage.value!.spaceTotal), "To"));

		cat.trial = {
			left: daysLeft,
			used: used.value,
			total: total.value,
			link: { name: RoutesLink.Params.Subscriptions.Index },
			danger: daysLeft <= TRIAL_DAY_LEFT_ALERT
		};
	}
}

function addObjectStorageAlerts (cat: MenuCategoryProps, clientId: string | null = null): void {
	if (hasAuthorization(RoleRules.ObjectStorage_Alerts)) {
		cat.items.push({
			name: "menu.object_storage_alerts",
			icon: "bell-notification",
			link: { name: RoutesLink.ObjectStorage.Alerts.Index, params: { clientId } },
			visible: true
		});
	}
}

function addObjectStorageIdentifier (cat: MenuCategoryProps, clientId: string | null = null): void {
	if (hasAuthorization(RoleRules.ObjectStorage_Identifier)) {
		cat.items.push({
			name: "menu.object_storage_identifiers",
			icon: "key-alt-plus",
			link: { name: RoutesLink.ObjectStorage.Identifiers.Index, params: { clientId } },
			visible: true
		});
	}
}

function addObjectStorageBucket (cat: MenuCategoryProps, clientId: string | null = null): void {
	if (hasAuthorization(RoleRules.ObjectStorage_Bucket)) {
		cat.items.push({
			name: "menu.object_storage_buckets",
			icon: "bitbucket",
			link: { name: RoutesLink.ObjectStorage.Buckets.Index, params: { clientId } },
			visible: true
		});
	}
}

function addSuperset (cat: MenuCategoryProps, clientId: string | null = null): void {
	cat.items.push({
		name: "menu.object_storage_usage",
		icon: "graph-up",
		link: { name: RoutesLink.ObjectStorage.Usage, params: { clientId } },
		visible: true
	});
}

const categoryS3 = computed<MenuCategoryProps>((): MenuCategoryProps => {
	const cat: MenuCategoryProps = {
		name: "menu.object_storage",
		icon: "substract",
		items: []
	};

	if (!showClientObjectStorage.value) return cat;

	if (objectStorage.value && !__subscription().has(Sub.Type.Partner)) {
		callOnce(() => void objectStorage.value!.fetch());

		addObjectStorageTrial(cat);

		useObjectStorageStats(objectStorage.value!);

		addObjectStorageIdentifier(cat);

		addObjectStorageBucket(cat);

		addObjectStorageAlerts(cat);

		addSuperset(cat);
	}

	return cat;
});

const categoryNextcloud = computed<MenuCategoryProps>((): MenuCategoryProps => {
	return {
		name: "menu.nextcloud_massive",
		icon: "frame-simple",
		items: []
	};
});

function updateProElementLink (
	pros: SubDrivePro[],
	proElement: MenuItemProps
): void {
	if (pros && pros.length <= 1 && pros[0]) {
		const instance: SubDrivePro = pros[0] as SubDrivePro;
		proElement.link
			= unref(instance && instance.url ? instance.url : "")
				|| "#url_pro=not-defined";
		proElement.external = true;

		if (instance && !instance.url) {
			console.error("L'url du drive pro n'est pas définie");
		}
	}
}

function addDriveProMenuItems (cat: MenuCategoryProps): void {
	if (
		drivePro.value
		&& (__pbUser().getUsername ? hasAuthorization(RoleRules.DrivePro) : true)
	) {
		const proElement: MenuItemProps = {
			name: "menu.drives_pro",
			icon: "large-suitcase",
			link: { name: RoutesLink.DrivePro.Index },
			visible: true,
			external: false
		};

		const pros = __subscription().getSubscriptions.filter(s => s.type === Sub.Type.DrivePro) as SubDrivePro[];

		updateProElementLink(pros, proElement);

		cat.items.push(proElement);
	}
}

function addDriveMenuItem (cat: MenuCategoryProps): void {
	if (
		drive.value
		&& (__pbUser().getUsername ? hasAuthorization(RoleRules.Drive) : true)
	) {
		cat.items.push({
			name: "menu.drives_solo",
			icon: "shopping-bag",
			link: config.public.URL_CLOUD,
			visible: true,
			external: true
		});
	}
}

const categoryDrive = computed<MenuCategoryProps>((): MenuCategoryProps => {
	const cat: MenuCategoryProps = {
		name: "menu.drives",
		icon: "folder",
		items: []
	};

	addDriveProMenuItems(cat);

	addDriveMenuItem(cat);

	return cat;
});

const categoryDiscovery = computed<MenuCategoryProps>((): MenuCategoryProps => {
	const cat: MenuCategoryProps = {
		name: "menu.discover",
		icon: "eye-alt",
		items: []
	};

	if (__pbUser().getUsername ? !hasAuthorization(RoleRules.Discover) : false) {
		return cat;
	}

	if (!__subscription().has(Sub.Type.ObjectStorage)) {
		cat.items.push({
			name: "menu.object_storage",
			icon: "substract",
			link: { name: RoutesLink.Discover.ObjectStorage },
			visible: true
		});
	}

	if (!__subscription().has(Sub.Type.Drive)) {
		cat.items.push({
			name: "menu.drives_solo",
			icon: "shopping-bag",
			link: { name: RoutesLink.Discover.Drive },
			visible: true
		});
	}

	if (!__subscription().has(Sub.Type.DrivePro)) {
		cat.items.push({
			name: "menu.drives_pro",
			icon: "large-suitcase",
			link: { name: RoutesLink.Discover.DrivePro },
			visible: true
		});
	}

	cat.items.push({
		name: "menu.nextcloud_massive",
		icon: "frame-simple",
		link: { name: RoutesLink.Discover.NextcloudMassif },
		visible: true
	});

	return cat;
});

const menuId = ref<string>("");
const menu = ref<MenuCategoryProps[]>();

watch([
	(): unknown => __subscription().getSubscriptions,
	categoryDiscovery,
	categoryDrive,
	categoryGeneral,
	categoryNextcloud,
	categoryPartners,
	categoryS3,
	(): unknown => __clients().getClientId
], renderMenu, { immediate: true });

const router = useRouter();

router.afterEach(() => {
	const id = router.currentRoute.value.params.clientId;
	const flatId: string = typeof id !== "string" ? id[0] : id;
	__clients().setClientId(flatId);
});

function renderMenu (): void {
	menuId.value = Math.random().toString(TOKEN_LENGTH);
	const menus = [];

	menus.push(categoryGeneral);

	if (hasAuthorization(RoleRules.Partner)) {
		menus.push(categoryPartners);
	}

	if (hasAuthorization(RoleRules.ObjectStorage)) {
		menus.push(categoryS3);
	}

	menus.push(categoryDrive);
	menus.push(categoryNextcloud);
	menus.push(categoryDiscovery);

	menu.value = menus
		.map(c => toValue<MenuCategoryProps>(c))
		.filter(c => c.items.length > 0);
}
</script>

<template>
	<nav class="menu">
		<div
			v-for="category in menu"
			:key="`cat-${category.name}-${menuId}`"
			class="menu__category"
		>
			<MenuCategory
				:icon="category.icon"
				:items="category.items"
				:name="category.name"
				:trial="category.trial ?? null"
			/>
		</div>
	</nav>
</template>

<style lang="scss" scoped>
.menu {
  @apply flex flex-col gap-6 text-sm;
}
</style>
