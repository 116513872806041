import { ref, type ToRefs } from "vue";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

export interface ISubscriptionPartner {
	spaceAllowed: number;
	spaceAllowedMax: number;
	clientsAmount: number;
	route: string;
	isUnlimited: boolean;
}

export type SubPartner = Subscription &
	ISubscriptionPartner & {
	setSpaceMaxAllowed: (newSpaceMax: number) => void;
};

/**
 * Represents a subscription partner.
 */
export default class SubscriptionPartner
	extends Subscription
	implements ToRefs<ISubscriptionPartner> {
	public static __token: string = "";

	public spaceAllowed = ref<number>(-1);

	public spaceAllowedMax = ref<number>(0);

	public clientsAmount = ref<number>(0);

	public route = ref<string>("");

	public isUnlimited = ref<boolean>(false);

	/**
	 *  Creates a new SubscriptionPartner instance.
	 *
	 * @param {SubscriptionConfig} config - The configuration object for the subscription partner
	 */
	public constructor (config: SubscriptionConfig) {
		super(config);

		if (!SubscriptionPartner.__token) {
			SubscriptionPartner.__token = getToken();
		}
	}
}
