<script setup lang="ts">
</script>

<template>
	<div class="sidebar">
		<div class="logo">
			<img
				class="logo__lg"
				alt="Leviia Logo"
				src="/images/leviia/logo.svg"
			/>
			<img
				class="logo__sm"
				alt="Leviia Logo"
				src="/images/leviia/logo-sm.svg"
			/>
		</div>
		<div class="menus">
			<Menu-Main />
			<Menu-Footer />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar {
	grid-template-rows: 28px 1fr;
	@apply w-full bg-dark h-full rounded-2xl p-2 pt-5 lg:px-4 lg:py-5 gap-[20px] lg:gap-[40px] text-light
    select-none grid justify-stretch;

	.logo {
		@apply flex justify-center items-center;

		&__lg {
			@apply hidden lg:block;
		}

		&__sm {
			@apply block lg:hidden;
		}
	}

	.menus {
		@apply flex flex-col h-full gap-[20px] lg:gap-[40px] w-full justify-between items-stretch;
	}
}
</style>
