import type { ComputedRef, Ref } from "vue";

import { __user } from "~/stores/user.store";

import { initKeycloak, keycloak } from "~/utils/keycloak";

import { routesKC } from "~/routes/declarations";

import type { Credentials } from "~/types/credentials";
import type { KCUser } from "~/types/user";

import { TEN_THOUSAND, TIMEOUT_SM } from "~/composables/config";
import { isDebugMode } from "~/composables/log";
const isAuthenticated = ref(false);
const user = computed<KCUser | undefined>(() => keycloak.tokenParsed as KCUser);

initKeycloak().then(() => loadUserInfo());

function loadUserInfo (): void {
	if (!keycloak.authenticated) {
		keycloak.login().then(r => r);
		return;
	}

	__user().setUser(user.value as KCUser);
	isAuthenticated.value = true;
}

export function useKeycloak (): {
	isAuthenticated: Ref<boolean>;
	user: ComputedRef<KCUser | undefined>;
	} {
	return {
		isAuthenticated,
		user
	};
}

export async function check2FA (username: string): Promise<boolean> {
	const res = await useFetchRoute<Credentials>(routesKC.getCredentialsForUsername, { username });
	if (!res || !res.credentials) {
		return false;
	}

	return res.credentials.some(cred => cred.type === "otp");
}

export async function getUser (username: string): Promise<KCUser | undefined> {
	const res = await useFetchRoute<KCUser>(routesKC.getUser, { username });
	if (!res) {
		return undefined;
	}

	return res;
}

export function waitForKC (): Promise<void> {
	if (isAuthenticated.value || isDebugMode()) {
		return Promise.resolve();
	}

	// wait until isAuthenticated is true, after 5s timeout, try every 100ms
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			if (!isOnDev()) reject(new Error("Keycloak timeout"));
		}, TEN_THOUSAND);

		const interval = setInterval(() => {
			if (isAuthenticated.value) {
				clearTimeout(timeout);
				clearInterval(interval);
				resolve();
			}
		}, TIMEOUT_SM);
	});
}
