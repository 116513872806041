import type { PublicRuntimeConfig, RuntimeConfig } from "nuxt/schema";
import type { Reactive } from "vue";

import { __alert } from "~/stores/alert.store";
import { __subscription } from "~/stores/subscription.store";

import { getCustomerID } from "~/utils/customer";

import { routesStripe } from "~/routes/declarations";

import type { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

import SubscriptionFactory from "~/classes/factories/SubscriptionFactory";
import type Subscription from "~/classes/Subscription";
import { SubscriptionConfig } from "~/classes/SubscriptionConfig";
import { SubscriptionValidator } from "~/classes/SubscriptionValidator";
import { hasFetchSubscriptions } from "~/composables/notifications";
import Status = Sub.Status;
import { PBEnums } from "~/types/pb/enums";
import SubscriptionType = PBEnums.SubscriptionType;
import { __pbUser } from "~/stores/pb-user.store";

import { fetchWithCache } from "~/utils/fetch";

import type { PBCollection } from "~/types/pb/collections";

import { PB } from "~/composables/db";

function waitForSubscriptions (): Promise<Reactive<Subscription>[]> {
	return new Promise(resolve => {
		const interval = setInterval(() => {
			if (__subscription().getIsFetched) {
				clearInterval(interval);
				resolve(__subscription().getSubscriptions);
			}
		}, 100);
	});
}

export async function getSubscriptionsByCustomerID (
	runtimeConfig: RuntimeConfig["public"],
	incomingCustomerId: string | null = null
): Promise<Reactive<Subscription>[]> {
	if (__subscription().getIsFetched) {
		return __subscription().getSubscriptions;
	}

	if (__subscription().getIsFetching) {
		return waitForSubscriptions();
	}

	__subscription().setFetching(true);
	__subscription().setFetched(false);

	log("🔍 Fetching subscriptions...");
	let customerID: string;
	try {
		customerID = await getCustomerID(incomingCustomerId);
	} catch (error) {
		console.error(error);
		__subscription().setFetched(true);
		__subscription().setFetching(false);
		return [];
	}

	log(`👤 Customer ID: %c${customerID}`, "font-weight: bold");
	let rawSubscriptions: Set<StripeRaw.Subscription>;
	try {
		rawSubscriptions = await fetchAllSubscriptions(customerID);
	} catch (error) {
		console.error("❌ Error fetching subscriptions:", error);
		__subscription().setFetching(true);
		__subscription().setFetched(false);
		return [];
	}

	const promises: Promise<Reactive<Subscription>[]>[] = [];

	const isPartner = checkPartner(rawSubscriptions);

	for (const subscription of rawSubscriptions) {
		let isClient = false;
		if (isPartner) {
			isClient = isTypeOfRawSubscription(subscription, Sub.Type.ObjectStorage);
		}

		promises.push(SubscriptionFactory.create(subscription, runtimeConfig, undefined, isClient));
	}

	const results = await Promise.all(promises);
	let subscriptions: Reactive<Subscription>[] = results.flat();
	const hasOS = subscriptions.some(subscription => subscription.getType() === Sub.Type.ObjectStorage);

	if (isPartner) {
		subscriptions = (await applyPartner(subscriptions, rawSubscriptions, runtimeConfig)).flat();
	} else if (hasOS) {
		const teamId = __pbUser().getTeam.id;
		if (!teamId) {
			console.error("No team id found");
			__subscription().setFetching(true);
			__subscription().setFetched(false);
			return;
		}

		const aboS3 = await PB.i
			.collection<PBCollection.AboS3>("abo_s3")
			.getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`, { fetch: fetchWithCache });
		if (!aboS3) {
			console.error("No abo_s3 found");
			__subscription().setFetching(true);
			__subscription().setFetched(false);
			return;
		}
		const subscriptionId = aboS3!.subscription_id!;
		subscriptions = subscriptions.filter(subscription => subscription.subscriptionItem.id === subscriptionId);

		__subscription().setFetching(true);
		__subscription().setFetched(false);
	}

	const subscriptionFiltered = filterSubscriptions(subscriptions);

	subscriptionFiltered.forEach(subscription => {
		// @ts-ignore
		__subscription().addSubscription(subscription);
	});

	if (subscriptionFiltered.length > 0 && !__subscription().hasOneOrMore(Status.Unpaid)) {
		__alert().remove("no-payment");
	}

	__subscription().setFetched(true);
	__subscription().setFetching(false);

	hasFetchSubscriptions.value = true;

	return subscriptionFiltered;
}

/**
 * Checks whether there are more than one valid "Object Storage" subscriptions
 * in the provided set of raw subscriptions.
 *
 * @param {Set<StripeRaw.Subscription>} rawSubscriptions - A set of raw subscription objects to be validated.
 * @return {boolean} Returns true if there are more than one valid "Object Storage" subscriptions, otherwise false.
 */
function checkPartner (rawSubscriptions: Set<StripeRaw.Subscription>): boolean {
	const subscriptions =  Array.from(rawSubscriptions)
		.filter(subscription => {
			const isOS = isTypeOfRawSubscription(subscription, Sub.Type.ObjectStorage);

			const isValid = SubscriptionValidator.isValidSubscriptionStatus(subscription.status);

			return isOS && isValid;
		}).length;
	if (!__pbUser().getTeam || !__pbUser().getTeam.abonnements) {
		return false;
	}

	const hasPartner = __pbUser().getTeam.abonnements?.includes(SubscriptionType.Partner) ?? false;
	return hasPartner && subscriptions >= 1;
}

/**
 * Applies a partner subscription to the list of existing subscriptions if certain conditions are met.
 *
 * @param {Reactive<Subscription>[]} subscriptions - The existing list of reactive subscriptions to which a partner subscription may be added.
 * @param {Set<StripeRaw.Subscription>} rawSubscriptions - A set of raw Stripe subscription data used for creating a partner subscription.
 * @param {PublicRuntimeConfig} runtimeConfig - The runtime configuration needed for subscription creation.
 * @return {Promise<Reactive<Subscription>[]>} - The updated list of reactive subscriptions, potentially including the new partner subscription.
 */
async function applyPartner (
	subscriptions: Reactive<Subscription>[],
	rawSubscriptions: Set<StripeRaw.Subscription>,
	runtimeConfig: PublicRuntimeConfig
): Promise<Reactive<Subscription>[]> {
	const osSubscriptions = subscriptions.filter(subscription => subscription.getType() === Sub.Type.ObjectStorage);
	if (osSubscriptions.length >= 1) {
		const firstSubscription: string = osSubscriptions[0].subscriptionItem.id;
		const firstRawSubscription = Array.from(rawSubscriptions).find(subscription => subscription.id === firstSubscription);
		const [ newSub ] = await SubscriptionFactory.create(firstRawSubscription, runtimeConfig, Sub.Type.Partner);
		subscriptions.push(reactive(newSub));
	}

	return subscriptions;
}

/**
 * Determines whether a given subscription is of a specific raw subscription type.
 *
 * @param {StripeRaw.Subscription} subscription - The raw subscription object to check.
 * @param {Sub.Type} subType - The type of subscription to compare against.
 * @return {boolean} - Returns true if the subscription matches the specified type, otherwise false.
 */
function isTypeOfRawSubscription (subscription: StripeRaw.Subscription, subType: Sub.Type): boolean {
	let isSubType = false;
	subscription.items.data.forEach(item => {
		[ item.metadata, item.plan.metadata ].forEach(metadata => {
			if (SubscriptionFactory.getSubscriptionType(metadata) === subType) {
				isSubType = true;
			}
		});
	});

	return isSubType;
}

async function fetchAllSubscriptions (customerId: string): Promise<Set<StripeRaw.Subscription>> {
	const subscriptions = new Set<StripeRaw.Subscription>();

	try {
		await fetchSubscriptionsPage(subscriptions, customerId);
	} catch (error) {
		console.error("❌ Error fetching subscriptions:", error);
		return subscriptions;
	}

	if (!subscriptions.size) {
		console.error("❌ No subscriptions found.");
	}

	return subscriptions;
}

async function fetchSubscriptionsPage (
	subscriptions: Set<StripeRaw.Subscription>,
	customerId: string,
	startingAfter?: string
): Promise<void> {
	const params = startingAfter ? { customerId, starting_after: startingAfter } : { customerId };

	const stripeSubscriptions = await useFetchRoute<StripeRaw.Subscriptions>(
		routesStripe.getSubscriptions,
		params
	);

	stripeSubscriptions.data.forEach(subscription => subscriptions.add(subscription));

	if (stripeSubscriptions.has_more) {
		await fetchSubscriptionsPage(
			subscriptions,
			customerId,
			stripeSubscriptions.data[stripeSubscriptions.data.length - 1].id
		);
	}
}

export function getMetadata (product: StripeRaw.ObjectWithMetadata): StripeRaw.Metadata {
	return product.metadata;
}

function sortSubscriptions (subscriptions: Reactive<Subscription>[]): Reactive<Subscription>[] {
	return subscriptions.sort((a, b) => {
		let sortScore: number;
		// 1. Order by subscription type
		sortScore = a.getType().localeCompare(b.getType()) * 100;

		// 2. Sort by status respecting the priority in SubscriptionConfig.subscriptionsPriority
		sortScore
			+= SubscriptionConfig.subscriptionsPriority.indexOf(a.getStatus())
			- SubscriptionConfig.subscriptionsPriority.indexOf(b.getStatus());

		if (sortScore === 0) {
			// 3. Sort by creation date
			sortScore = a.getCreated(true) > b.getCreated(true) ? 1 : -1;
		}

		return sortScore;
	});
}

function logFilteredSubscriptionWarn (
	subscription: Reactive<Subscription>,
	subscriptionsMap: Record<Sub.Type, Reactive<Subscription>[]>,
	type: Sub.Type,
	statusA: Sub.Status,
	statusB: Sub.Status
): void {
	const boldCss = "font-weight: bold";
	const normalCss = "font-weight: normal";
	const isSameStatus = statusA === statusB;
	const typeStatus = `💥 Subscription filtered: ${subscription.getType()} ${subscription.getStatus()} already exists.\nKeeping`;
	const keptValue = isSameStatus ? subscription.subscriptionItem.id : statusA;
	const discardedValue = isSameStatus ? subscriptionsMap[type][0].subscriptionItem.id : statusB;
	console.warn(
		`${typeStatus} "%c${keptValue}%c" instead of "%c${discardedValue}%c"`,
		boldCss,
		normalCss,
		boldCss,
		normalCss
	);
}

function filterSubscriptions (subscriptions: Reactive<Subscription>[]): Reactive<Subscription>[] {
	sortSubscriptions(subscriptions);

	const subscriptionMap: Record<Sub.Type, Reactive<Subscription>[]> = {
		[Sub.Type.Drive]: [],
		[Sub.Type.DrivePro]: [],
		[Sub.Type.Partner]: [],
		[Sub.Type.ObjectStorage]: []
	};

	subscriptions.forEach(subscription => {
		const type = subscription.getType();
		if (!subscriptionMap[type]) {
			subscriptionMap[type] = [];
		}

		if (SubscriptionValidator.canHaveMultipleSubscriptions(type)) {
			subscriptionMap[type].push(subscription);
			return;
		}

		if (!subscriptionMap[type].length) {
			subscriptionMap[type].push(subscription);
			return;
		}
		subscriptionMap[type].forEach(existingSubscription => {
			const hasPriority = SubscriptionValidator.isMostHighPriority(
				subscription.getStatus(),
				existingSubscription.getStatus()
			);

			if (hasPriority) {
				subscriptionMap[type] = [ subscription ];
				return;
			}

			logFilteredSubscriptionWarn(
				subscription,
				subscriptionMap,
				type,
				subscription.getStatus(),
				existingSubscription.getStatus()
			);
		});
	});

	return Object.values(subscriptionMap).flat();
}
