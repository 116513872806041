<script lang="ts" setup>
import { __alert } from "~/stores/alert.store";

import { getSubscriptionsByCustomerID } from "~/utils/subscriptions";
import { observeDOMChanges } from "~/utils/theme";

import "@/assets/css/icons.css";

import { globalT } from "~/composables/i18n";
import { useKeycloak } from "~/composables/keycloak";
import { isDebugMode } from "~/composables/log";
import { useMeta } from "~/composables/meta";
import { checkNotifications } from "~/composables/notifications";

const { isAuthenticated } = useKeycloak();
const { t } = useI18n();
globalT.value = t;
const config = useRuntimeConfig().public;

let fontSize = "16px";
// if windows height is less than 800px, set font size to 14px
// eslint-disable-next-line no-magic-numbers
if (typeof window !== "undefined" && window.innerHeight < 800) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	fontSize = "8px";
}

checkNotifications(config);

onMounted(async () => {
	await getSubscriptionsByCustomerID(config);
});

// On press Ctrl, clear the local storage
onMounted(() => {
	window.addEventListener("keydown", e => {
		if (e.ctrlKey && e.key === "r") {
			localStorage.clear();
		}
	});
	observeDOMChanges();
});

const route = useRoute();

watch(
	() => route.fullPath,
	(newPage, _oldPage) => {
		if (!newPage.includes("/object-storage")) {
			__alert().remove("client-selector-info", true, true);
		}
	},
	{ immediate: true , deep: true }
);
</script>

<template>
	<div
		v-if="isAuthenticated || isDebugMode()"
		class="app-container"
	>
		<span v-if="isDebugMode()" class="debug-mode-alert opacity-70 mt-1">
			DEBUG MODE, BEAUCOUP DE FONCTIONNALITÉS PEUVENT NE PAS FONCTIONNER
		</span>
		<div class="top-bar" />
		<div class="app">
			<div class="sidebar">
				<Layout-Sidebar />
			</div>
			<div class="content">
				<div
					v-if="useMeta().title !== '' || useMeta().description !== ''"
					class="header"
				>
					<Layout-Header />
				</div>
				<div class="body">
					<div class="view">
						<div class="page">
							<NuxtPage />
						</div>
					</div>
					<div class="footer">
						<Layout-Footer />
					</div>
				</div>
			</div>
			<WelcomeModal />
		</div>
	</div>
	<div
		v-else
		class="main-loader__container"
	>
		<Loader
			class="main-loader"
			classes="h-32"
		/>
	</div>
</template>

<style lang="scss">
@use "@/assets/css/elements.scss";
@use "@/assets/css/variables.scss";
@use "@/assets/css/function.scss";
@use "@/assets/css/default.scss";

:root {
	@apply text-[12px] lg:text-[16px];
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

.main-loader {
	&__container {
		@apply flex justify-center items-center h-screen;
	}
}

.app {
	@apply bg-lighter;
	grid-template-columns: auto 1fr;
	@media (width > 1280px) {
		grid-template-columns: calc(180px + 1.25rem) 1fr;
	}

	&-container {
		.top-bar {
			@apply fixed h-1 bg-primary w-full z-10;
		}
	}

	@apply fixed top-0 h-screen grid gap-2 lg:gap-4 p-2 pt-3 lg:pl-4 lg:pt-4 pb-0 lg:pr-1 lg:pr-2 w-full;

	.sidebar {
		@apply mb-2 lg:mb-4;
	}

	.content {
		@apply flex flex-col gap-4 w-full col-start-2 overflow-hidden;

		.header {
			@apply h-auto w-full flex gap-4 pr-1;
		}

		.body {
			--radius: 20px 20px 0 0;
			@apply flex flex-col gap-5 justify-between w-full h-full pr-2 overflow-y-auto overflow-x-hidden;
			@extend %scrollable;

			.view {
				@apply flex flex-col gap-4 pt-1 transition-all duration-150;

				&--is-scrolled,
				&--has-rightbar {
					@apply pt-0;
				}

				&--without-rightbar {
					@apply pt-1;
				}

				.page {
					@apply grid gap-4;

					&.with-rightbar {
						@apply grid;
						grid-template-columns: 1fr 300px;
					}

					.box:not(:last-child) {
						@apply mb-4;
					}
				}
			}
		}

		.footer {
			@apply h-auto py-2;
		}
	}
}

hr {
	@apply border-0 border-b-cream my-[16px] border-b-[2px];
}

.with-rightbar {
	@apply grid gap-5;
	grid-template-columns: 1fr 300px;
}

.page-enter-active,
.page-leave-active {
	transition: all 50ms ease-in-out;
}

.page-enter-from,
.page-leave-to {
	opacity: 0;
	transform: translateY(5px);
}

.debug-mode-alert {
	background-color: red;
	color: yellow;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: .5rem;
	z-index: 999;
	font-weight: bold;
	user-select: none;
	pointer-events: none;
	text-align: center;
}
</style>
