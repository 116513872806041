import Keycloak from "keycloak-js";

import { getEnv } from "~/utils/polyfill";

import { version } from "../../package.json";

import { KC_CHECK_INTERVAL } from "~/composables/config";

console.info(
	"%cDashboard Leviia%c %c" + version + "%c",
	"font-weight: bold; font-size: 2rem; margin-right: .5rem",
	"",
	"color: #00C599; font-weight: bold; font-size: 1.25rem",
	""
);

const config = getEnv();

export const keycloak = new Keycloak({
	url: config.KC_URL,
	realm: config.KC_REALM,
	clientId: config.KC_CLIENT_ID
});

export const tokenDuration = 1800; // in seconds
export const currentAccessToken = ref("");

export function initKeycloak (): Promise<boolean> {
	// Refresh token every 58 seconds
	setInterval(updateToken, KC_CHECK_INTERVAL * SEC_IN_MS);

	return keycloak.init({
		onLoad: "check-sso",
		silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso",
		checkLoginIframeInterval: tokenDuration * SEC_IN_MS,
		checkLoginIframe: false
	});
}

function updateToken (): void {
	keycloak
		.updateToken(tokenDuration)
		.then(refreshed => {
			if (refreshed) {
				currentAccessToken.value = keycloak.token as string;
			}
		})
		.catch(() => {
			console.error("Failed to refresh token");
		});
}

keycloak.onAuthSuccess = (): void => {
	currentAccessToken.value = keycloak.token as string;

	setInterval(updateToken, tokenDuration * SEC_IN_MS);
};
