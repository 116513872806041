export const RoutesLink = {
	Home: "home",
	Logout: "logout",

	Discover: {
		Drive: "discover.drive",
		DrivePro: "discover.drive_pro",
		NextcloudMassif: "discover.nextcloud_massif",
		ObjectStorage: "discover.object_storage"
	},

	ObjectStorage: {
		Buckets: {
			Index: "object_storage.buckets",
			Create: "object_storage.buckets.create",
			Edit: "object_storage.buckets.edit"
		},
		Identifiers: {
			Index: "object_storage.identifiers",
			Create: "object_storage.identifiers.create",
			Edit: "object_storage.identifiers.edit"
		},
		Usage: "object_storage.usage",
		Alerts: {
			Index: "object_storage.alerts"
		}
	},

	Partner: {
		Index: "partner.index"
	},

	DrivePro: {
		Index: "drive_pros"
	},

	Params: {
		Billing: {
			Details: "params.billing.details",
			Invoices: "params.billing.invoices",
			PaymentMethods: "params.billing.payment_methods"
		},
		Subscriptions: {
			Index: "params.subscriptions",
			NoSub: "params.subscriptions.no_sub",
			Drive: "params.subscriptions.drive",
			DrivePro: "params.subscriptions.drive_pro",
			ObjectStorage: "params.subscriptions.object_storage",
			Partner: "params.subscriptions.partner"
		},
		Team: {
			Index: "params.team",
			Create: "params.team.create",
			Edit: "params.team.edit"
		},
		Infos: "params.infos",
		Security: "params.security"
	}
} as const;

export function getRoutePath (route: string): string {
	const router = useRouter();
	const paths = router.getRoutes().filter(r => r.name === route);

	if (paths.length === 0) {
		console.error(`Route ${route} not found`);
		return "/";
	}

	return paths[0].path;
}
