import * as dotenv from "dotenv";
import * as fs from "fs";

import envBackup from "../../env.preprod.json";

/**
 * Lit le fichier d'environnement et retourne une liste de clés d'environnement
 * @param path - Le chemin vers le fichier d'environnement
 * @returns Une liste de clés d'environnement
 */
function readEnvFile (path: string): string[] {
	const envList: string[] = [];

	const fileContent: string = fs.readFileSync(path, "utf8");
	const lines: string[] = fileContent.split(/\r?\n/);

	for (const line of lines) {
		const trimmedLine: string = line.trim();

		// Ignore les commentaires et les lignes vides
		if (trimmedLine.length === 0 || trimmedLine.startsWith("#")) {
			continue;
		}

		const match: RegExpMatchArray | null = /^([^=]+)=(.*)$/.exec(trimmedLine);

		if (match) {
			const key: string = match[1].trim();
			envList.push(key);
		}
	}

	return envList;
}

/**
 * Retourne un objet contenant les valeurs des variables d'environnement spécifiées dans le fichier
 * @param envFile - Le chemin vers le fichier d'environnement
 * @returns Un objet contenant les valeurs des variables d'environnement
 */
export function getEnvVariables (envFile: string): Record<string, unknown> {
	const envVariables: Record<string, unknown> = {};

	let envKeys: string[];
	try {
		dotenv.config({ path: envFile });
		envKeys = readEnvFile(envFile);
	} catch (error) {
		console.error("Error loading env file", error);
		console.warn("Using backup env file", envBackup);
		return envBackup;
	}

	for (const key of envKeys) {
		envVariables[key] = process.env[key];
		if (key === "APP_URL") {
			if (!process.env["NUXT_ENV"]) {
				envVariables[key] = "http://localhost:3000/";
				console.warn("NUXT_ENV is not defined, defaulting APP_URL to http://localhost:3000/");
			}
		}
	}

	return envVariables;
}
