/* eslint-disable no-magic-numbers */

export const TOKEN_LENGTH = 16;

export const SEC_IN_MS = 1_000;
export const SEC_HALF_MS = 500;
export const SEC_QUART_MS = 250;
export const KC_CHECK_INTERVAL = 58;

export const TIMEOUT_SM = 100;

export const THOUSAND = 1_000;
export const TEN_THOUSAND = 10_000;

export const TO_FIXED_2 = 2;
export const TO_FIXED_3 = 3;

export const WITHOUT_3 = -3;

export const BYTE_MULTIPLIER = 1_000;
export const BYTE_POW_MO = 2;
export const BYTE_POW_GO = 3;
export const BYTE_POW_TO = 4;
export const BYTE_POW_PO = 5;

export const BYTES_IN_TO = 1000000000000;

export const YEAR_IN_DAYS = 365;

export const FULL_ANGLE = 360;

export const DATE_TO_JSDATE_MULTIPLIER = 1_000;
export const DATE_BASE_FORMAT_LEN = 1_000;
export const DATE_DAY_SECS = 86_400_000;

export const DEFAULT_ALERT_LIMIT = 75;

export const COPY_WAIT_DURATION = 2_000;

export const FAKE_STRIPE_WAIT_DURATION = 2_000;
export const MAX_WAIT_SUB = 5_000;
export const MAX_LONGWAIT_SUB = 5_000;

export const PERCENT_TO_WARN = 70;
export const PERCENT_TO_DANGER = 90;

export const PHONE_LEN = 9;

export const KNOB_BORDER_WIDTH = 2;

export const TRIAL_DAY_LEFT_ALERT = 2;
export const MONTH_DATES_LEN = 30;

export const COLOR_MAX = 255;
export const COLOR_IS_DARK_LIMIT = 50;

export const TABLE_ROWS = 10;
export const TABLE_ROWS_OPTIONS = [
	5,
	10,
	20,
	50
];

export const IDENTIFIER_MAX_SIZE = 30;

export const BUCKET_NAME_LEN_MAX = 63;
export const BUCKET_NAME_LEN_MIN = 3;
