import { faker } from "@faker-js/faker";

import { BYTE_MULTIPLIER } from "~/composables/config";

/**
 * Retrieve a complete URL by prefixing "https://" if the provided URL does not already start with "http".
 *
 * @param {string} urlPro - The URL to be processed.
 * @return {string} - The modified URL.
 */
export function getUrlFrom (urlPro: string): string {
	if (!urlPro) {
		return "";
	}

	if (!urlPro.startsWith("http")) {
		urlPro = "https://" + urlPro;
	}

	return urlPro;
}

/**
 * Formats the given phone number to a standard format.
 *
 * @param {string} phone - The phone number to be formatted.
 * @returns {string} - The phone number in standard format.
 */
export function phoneFormatStandard (phone: string): string {
	if (!phone) {
		return "";
	}

	return phone
		.replace(/^\+33/, "0")
		.padStart(10, "0")
		.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
}

/**
 * Defines the usage of a product.
 *
 * @param {any} product - The product object.
 * @returns {string} - The usage description.
 *
 * @example
 * defineUsage({ metadata: { space_allowed: BYTE_MULTIPLIER } }, t); // "1 To / BYTE_MULTIPLIER To"
 */
export function defineUsage (product: any): string {
	const usageRaw = faker.number.float({
		min: 0,
		max: 3,
		multipleOf: 0.0001
	}); // en To

	const spaceAllowed = product.metadata.space_allowed ?? 0;
	const isGo = usageRaw < 1;

	// Si usage en Go, arrondir, sinon montrer une décimale
	const formattedUsage = isGo ? Math.round(usageRaw * BYTE_MULTIPLIER) : usageRaw.toFixed(1);

	const usageStr = `${formattedUsage} ${isGo ? translate("unit.gb") : translate("unit.tb")}`;

	const sizeMax = spaceAllowed > 0 ? `/ ${spaceAllowed} To` : `(${translate("label.unlimited")})`;

	return `${usageStr} ${sizeMax}`;
}

/**
 * Returns the region and cluster number from a given cluster string.
 *
 * @param {string} clusterString - The cluster string in the format "REGIONCLUSTER".
 * @returns {Object} An object containing the region and cluster number.
 * @throws {Error} Throws an error if the cluster string is invalid.
 *
 * @example
 * getClusterRegionFromPBCluster("fr2"); // { region: "fr", cluster: 2 }
 *
 * @example
 * getClusterRegionFromPBCluster("idf57"); // { region: "idf", cluster: 57 }
 */
export function getClusterRegionFromPBCluster (clusterString: string): {
	region: string;
	cluster: number;
} {
	const match = /(.*)(\d)$/.exec(clusterString);
	if (!match) {
		console.error("Invalid string format", clusterString);
		throw new Error("Invalid cluster string format");
	}

	return {
		region: match[1],
		cluster: Number(match[2])
	};
}

/**
 * Generates a simple hash string based on the input string. The function uses bitwise operations
 * to compute a hash value, then converts it to a base-36 string for compact representation.
 *
 * @param {string} input - The input string to hash.
 * @return {string} - A base-36 representation of the hash value.
 */
export function simpleHash (input: string): string {
	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		const char = input.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash |= 0;
	}
	// eslint-disable-next-line no-magic-numbers
	return hash.toString(36);
}
