import type { Router } from "vue-router";

import type { Meta } from "~/types/layout";

import { setActiveRoute } from "~/composables/routes";
import type { TradKey } from "~/plugins/i18n";

const meta = reactive<Meta>({
	title: "",
	description: "",
	back: ""
});

export function useMeta (): Meta {
	return meta;
}

function setBack (back: Meta["back"], router: Router): void {
	let backRoute: any;
	if (!meta) {
		console.error("Meta is not defined");
	}

	if (typeof back === "string") {
		backRoute = router.getRoutes().filter(route => route.name === back);

		if (back.trim().length > 0 && backRoute.length === 0) {
			console.error(`Route ${back} not found`);
		} else if (back && back !== "") {
			meta.back = backRoute[0].path;
		} else {
			meta.back = "";
		}
	} else {
		meta.back = back;
	}
}

export function setMeta (
	title: TradKey | "" = "",
	description: TradKey | "" | { message: TradKey, params: object } = "",
	activeMenu = "",
	back: Meta["back"] | null = null
): void {
	const router = useRouter();
	meta.title = title;
	meta.description = description;

	setBack(back, router);

	if (title !== "") {
		// Update the document title
		document.title = `${translate(title)} - Console Leviia`;
	}

	// Update active route
	setActiveRoute(activeMenu);
}
