import type { ToRefs } from "vue";

import { __clients } from "~/stores/clients.store";
import { __pbUser } from "~/stores/pb-user.store";
import { __subscription } from "~/stores/subscription.store";

import { fetchWithCache } from "~/utils/fetch";

import type { PBCollection } from "~/types/pb/collections";
import { Sub } from "~/types/subscriptions";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

/**
 * Represents the subscription information for an object storage.
 *
 * @interface ISubscriptionObjectStorage
 * @property {boolean} isActive - The status of the subscription
 * @property {boolean} isDisabled - The status of the subscription
 * @property {boolean} isLoading - The status of the subscription
 * @property {number} bucketsAmount - The amount of buckets
 * @property {string} bucketsRoute - The route to the buckets
 * @property {number} identifiersAmount - The amount of identifiers
 * @property {string} identifiersRoute - The route to the identifiers
 * @property {number} objectsAmount - The amount of objects
 * @property {number} spaceDisposed - The disposed space
 * @property {number} spaceTotal - The total space
 * @property {number} spacePercent - The percentage of space used
 */
export interface ISubscriptionObjectStorage {
	bucketsAmount: number;
	bucketsRoute: string;
	identifiersAmount: number;
	identifiersRoute: string;
	objectsAmount: number;
	spaceDisposed: number;
	spaceTotal: number;
	spacePercent: number;
	isUnlimited: boolean;
	isQuotaReached: boolean;
}

export type SubObjectStorage = Subscription &
	ISubscriptionObjectStorage & {
	setSpaceTotal: (space: number) => void;
	incrementObjectsAmount: (amount: number) => void;
	incrementSizeDisposed: (size: number) => void;
	reset: () => void;
	fetch: () => Promise<void>;
};

/**
 * Represents a subscription object storage.
 */
export default class SubscriptionObjectStorage
	extends Subscription
	implements ToRefs<ISubscriptionObjectStorage> {
	public bucketsAmount = ref<number>(0);

	public bucketsRoute = ref<string>("");

	public identifiersAmount = ref<number>(0);

	public identifiersRoute = ref<string>("");

	public objectsAmount = ref<number>(0);

	public spaceDisposed = ref<number>(0);

	public spaceTotal = ref<number>(0);

	public spacePercent = ref<number>(0);

	public isUnlimited = ref<boolean>(false);

	public isQuotaReached = computed<boolean>(() => this.spaceDisposed.value >= this.spaceTotal.value);

	/**
	 *  Creates a new SubscriptionObjectStorage instance.
	 *
	 * @param {SubscriptionConfig} config - The configuration object for the subscription object storage
	 */
	public constructor (config: SubscriptionConfig) {
		super(config);

		// if (config.isClient) {
		void this.toClient();
		// }
	}

	/**
	 * Set space total.
	 *
	 * @param {number} space - The space total
	 */
	public setSpaceTotal (space: number): void {
		this.spaceTotal.value = space;
		this.isUnlimited.value = space === -1;
	}

	/**
	 * Increment objects amount.
	 *
	 * @param {number} amount - The amount to increment
	 * @returns {void}
	 */
	public incrementObjectsAmount (amount: number): void {
		this.objectsAmount.value += amount;
	}

	/**
	 * Increment size disposed.
	 *
	 * @param {number} size - The size to increment
	 * @returns {void}
	 */
	public incrementSizeDisposed (size: number): void {
		this.spaceDisposed.value += size;
	}

	/**
	 * Reset the objects amount and size disposed.
	 */
	public reset (): void {
		this.objectsAmount.value = 0;
		this.spaceDisposed.value = 0;
	}

	/**
	 * Fetch object storage data.
	 *
	 * @returns {Promise<void>}
	 */
	public async fetch (): Promise<void> {
		__subscription().setLoading(this.type, true);
		await waitForPB();
		const teamId = __pbUser().getTeamId;
		if (!teamId) {
			console.warn("No team ID found");
			__subscription().setLoading(this.type, false);
			return;
		}

		try {
			const aboS3 = await PB.i
				.collection("abo_s3")
				.getFirstListItem<PBCollection.AboS3>(`equipe="${teamId}"`, {
					requestKey: `abo_s3-${teamId}`,
					fetch: fetchWithCache
				});
			if (aboS3) {
				this.setSpaceTotal(aboS3.quota);
			}
		} catch {
			console.warn(`Can't fetch "abo_s3" for team : %c${teamId}`, "font-weight: bold");
		} finally {
			__subscription().setLoading(this.type, false);
		}
	}

	/***********************************************************************************************/

	/**
	 * Fetches the clients for the subscription partner.
	 *
	 * @returns {Promise<void>} - A promise that resolves when the clients are fetched
	 */
	private async toClient (): Promise<void> {
		__subscription().setLoading(Sub.Type.Partner, true);
		await waitForPB();
		let subsOS: PBCollection.AboS3 & { expand: { equipe: PBCollection.Equipes } };
		try {
			subsOS = await PB.i
				.collection<PBCollection.AboS3 & {
					expand: { equipe: PBCollection.Equipes; clients_infos: PBCollection.ClientsInfos }
				}>("abo_s3")
				.getFirstListItem(
					`subscription_id = "${this.subscriptionItem.id.trim()}"`,
					{ expand: "equipe", fetch: fetchWithCache }
				);
		} catch (error) {
			console.error(`❌ Can't fetch "abo_s3" for subscription : %c${this.subscriptionItem.id}`, "font-weight: bold", error);
			return;
		}

		if (!subsOS || !subsOS.expand) {
			console.error(`❌ No subscription found for client ${this.subscriptionItem.id}`);
			return;
		}

		const team = subsOS.expand.equipe;
		const clientInfos = await PB.i.collection("clients_infos")
			.getFirstListItem<PBCollection.ClientsInfos>(`id="${team.id}"`, { fetch: fetchWithCache });

		__clients().addClient({
			id: subsOS?.id,
			teamId: team.id,
			ref: team.reference,
			creationDate: new Date(this.subscriptionItem.created * DATE_TO_JSDATE_MULTIPLIER),
			subscriptionEnd: new Date(this.subscriptionItem.current_period_end * DATE_TO_JSDATE_MULTIPLIER),
			storage: clientInfos.allowed,
			quota: clientInfos.quota,
			isActive: !team.leviia_deactivated && !team.parent_deactivated,
			manageMode: subsOS.s3_mode || null,
			loading: false
		});

		this.setSpaceTotal(subsOS.quota);

		__subscription().setLoading(Sub.Type.Partner, false);
	}
}
